import React from 'react'
import { useQuery, gql } from '@apollo/client';
const lastBrochures = gql`
    query {
        allBrochures (
        first: 1
        sortBy: id_DESC
        ){
        id
        Titre
        Fichier {
            filename
        }
        }
    }
`;
const InformationImportante = () => {
    const { loading, error, data } = useQuery(lastBrochures);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    return (
        <>
            <h2 className="hide-temp">Brochure d’informations</h2>
            <div className="brochures-infos hide-temp">

                <ul>
                    {
                        data && data.allBrochures ? data.allBrochures.map((brochure) => (
                            <li>
                                <a href={'uploads/' + brochure.Fichier.filename} rel="noopener noreferrer"  target="_blank" download>{brochure.Titre}</a>
                            </li>
                        )) : ''
                    }
                </ul>
            </div>
        </>
    )
}
export default InformationImportante

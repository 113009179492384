import React, { Fragment } from 'react'
import { useQuery, gql } from '@apollo/client';
import Proprietaire from '../../../assets/images/header_credit.jpg'
import { Link } from 'react-router-dom'

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "CREDIT"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const BRIEFING_OPTIONS = gql`
    query {
        allPages (
            where: {
                Nom: "CREDIT"
                BriefingOptions_every: {
                    PageCurrant: {
                        Nom: "CREDIT"
                    }
                }
            }
        ){
      id
      Nom
      BriefingOptions {
        id
        Titre
        PageCurrant {
          id
          Nom
          Lien
        }
        PageRedirection {
          id
          Nom
          Lien
        }
        Image {
          id
          filename
        }
        Contenu
      }
    }
  }
`;

const ASIDES = gql`
    query {
        allPages (
            first: 1
            where: {
                Nom: "CREDIT"
                Asides_every: {
                    Page_every: {
                        Nom: "CREDIT"
                    }
                }
            }
        ){
            id
            Nom
            Asides{
                id
                Titre
                SousTitre
                Priorite
                Description
                Link {
                id
                Nom
                Lien
                }
            }
        }
    }
`;


function CreditArticle() {

    const { loading, error, data } = useQuery(ACCROCHES);
    const { loading: loadingBriefing, error: errorBriefing, data: dataBriefing } = useQuery(BRIEFING_OPTIONS);
    const { loading: loadingASIDES, error: errorASIDES, data: dataBASIDE } = useQuery(ASIDES);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (loadingBriefing) return <p>Loading...</p>;
    if (errorBriefing) return <p>Error :(</p>;

    if (loadingASIDES) return <p>Loading...</p>;
    if (errorASIDES) return <p>Error :(</p>;


    return (
        <Fragment>
            <div class="slide-home">
                <div class="content-slides">
                    <div class="images">
                        <img src={Proprietaire} alt="Ceanet" />
                    </div>

                </div>
            </div>
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                       <div key={accroche.id} className="col-md-12 info-home">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="info-contact info-one-column">
                                        <h1>{accroche.Titre}</h1>
                                        <p>{accroche.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )): ''
            }
            <div id="main-wrapper" class="col-md-12">
                <div class="container">
                    <div class="side-left">
                    {
                            dataBriefing && dataBriefing.allPages && dataBriefing.allPages[0] && dataBriefing.allPages[0].BriefingOptions ? dataBriefing.allPages[0].BriefingOptions.map((briefing, index) => (
                                <div className="bloc_credit bloc-info" key={briefing.id}>
                                  <img src={briefing.Image ? 'uploads/' + briefing.Image.filename : ''} alt={briefing.Image.filename} alt="" />
                                  <div className="info">
                                      <h2>{briefing.Titre}</h2>
                                      <div className="desc" dangerouslySetInnerHTML={{ __html: briefing.Contenu}}></div>
                                      <Link class="credit_link" to={briefing.PageRedirection ? briefing.PageRedirection.Lien : '#'}> Faites votre demande</Link>
                                  </div>


                                </div>

                            )) : ''
                        }
                    </div>

                    <div class="side-right">
                        {
                            dataBASIDE && dataBASIDE.allPages && dataBASIDE.allPages[0] && dataBASIDE.allPages[0].Asides ? dataBASIDE.allPages[0].Asides.map((aside) => (
                                <>
                                    <h2>{aside.Titre}</h2>
                                    <div class="actualites">
                                        <div class="right-comptes">
                                            <h3>{aside.SousTitre}</h3>
                                            <p>{aside.Description}</p>
                                            <Link class="credit_link" to = {aside.Link ? aside.Link.Lien : '#'}> {aside.Link ? aside.Link.Nom : 'Faites votre demande'}</Link>
                                        </div>
                                    </div>
                                </>
                            )) : ''
                        }

                        <h2 className="hide-temp">Brochure d’informations</h2>
                        <div className="brochures-infos hide-temp">
                            <ul>
                                <li><Link to={"/"}>Titre de la brochure</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CreditArticle

import React, { Fragment, Component } from 'react'
import SideBtnLink from '../../Aside/SideBtnLink'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import ContactHeaderSlider from './ContactHeaderSlider'
import ContactMainContent from './ContactMainContent'
import Header from '../../Header/Header'
import { Helmet } from 'react-helmet'


class Contact extends Component {

    componentDidMount() {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }

    render() {
        return (
            <Fragment>
              <Helmet>
                <title>Caisse d’épargne d’Aubonne - Contact</title>
                <meta name="description" content="Pour chaque situation, nous essayons de trouver une solution. Nous sommes à votre écoute pour vous aider dans vos projets." />
              </Helmet>
                <Header />
                <ContactHeaderSlider />
                <ContactMainContent />
                <SideBtnLink />
                <Footer />
                <Copyright />
            </Fragment>

        )
    }


}

export default Contact

import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import   ConditionContent from '../Conditions/ConditionContent'
import { Helmet } from 'react-helmet'

 const Conditions = () =>  {

        useEffect(() => {
            setTimeout(() => {
                if(!window.location.hash){
                    window.scrollTo(0,0);
                }

            }, 500)
        }, [])

        return (
            <Fragment>
              <Helmet>
                <title>Caisse d’épargne d’Aubonne - Conditions</title>
                <meta name="description" content="Bienvenue sur la page d’accueil de la Caisse d'Epargne d'Aubonne est une société coopérative implantée dans les districts de Morges et de Nyon, dans le canton de Vaud." />
              </Helmet>
                <Header />
                <SideBtnLink />
                <ConditionContent />
                <Footer />
                <Copyright />
            </Fragment>
        )

}

export default Conditions

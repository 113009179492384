import React, { useEffect, useState } from "react";
// import "@fieldify/antd/dist/index.css";
import { Radio, Col, Row, Checkbox, Steps, Button, message, Timeline, Tooltip, Form, Input, Select } from "antd";
import { BorderBottomOutlined, TrophyTwoTone } from "@ant-design/icons";

import COUNTRIES from './countries.json';

function QuestionEconomique({ questionnaireEconomiqueReponse, setQuestionnaireEconomiqueReponse, setCurrentParentIndex}) {
  const [questions, setQuestions] = useState([
    {
      key: 'professionnelle',
      titre: 'Professionnelle',
      question: "Situation professionnelle",
      responses: [
        {
          label: 'Employé',
          value: 'Employé'
        },
        {
          label: 'Indépendant',
          value: 'Indépendant'
        },
        {
          label: 'Retraité',
          value: 'Retraité'
        },
        {
          label: 'En formation',
          value: 'En formation'
        },
        {
          label: 'Sans activité lucrative',
          value: 'Sans activité lucrative'
        }
      ]
    },
    {
      key: 'revenubrutannuel',
      titre: 'Revenu brut annuel',
      question: "Revenu brut annuel",
      responses: [
        {
          label: "Jusqu'à CHF 40'000",
          value: "Jusqu'à CHF 40'000"
        },
        {
          label: "CHF 40' - 80'000",
          value: "CHF 40' - 80'000"
        },
        {
          label: "CHF 80' - 120'000",
          value: "CHF 80' - 120'000"
        },
        {
          label: "Plus de CHF 120'000",
          value: "Plus de CHF 120'000"
        }
      ]
    },
    {
      key: 'originefortune',
      titre: 'Origine de la fortune',
      question: "Origine de la fortune principale",
      responses: [
        {
          label: 'Salaire / Revenu',
          value: 'Salaire / Revenu'
        },
        {
          label: 'Héritage / Donation',
          value: 'Héritage / Donation'
        },
        {
          label: "Profits d'investissement",
          value: "Profits d'investissement"
        },
        {
          label: 'Vente du commerce',
          value: 'Vente du commerce'
        },
        {
          label: 'Vente de bien immbiliers',
          value: 'Vente de bien immbiliers'
        },
        {
          label: 'Gains de loterie / jeux',
          value: 'Gains de loterie / jeux'
        },
        {
          label: 'Autres',
          value: 'Autres'
        }
      ]
    },
    {
      key: 'apportsinitiaux',
      titre: 'Apports initiaux',
      question: "Apports initiaux lors de l'ouverture du compte",
      responses: [
        {
          label: "jusqu'à CHF 10'000",
          value: "jusqu'à CHF 10'000"
        },
        {
          label: "CHF 10' - 50'000",
          value: "CHF 10' - 50'000"
        },
        {
          label: "CHF 50' - 100'000",
          value: "CHF 50' - 100'000"
        },
        {
          label: "Plus de CHF 100'000",
          value: "Plus de CHF 100'000"
        }
      ]
    },
    {
      key: 'origineFonds',
      titre: 'Origine des fonds',
      question: "Origine des fonds apportés",
      responses: [
        {
          label: "Activité professionnelle",
          value: "Activité professionnelle"
        },
        {
          label: "Héritage ou donation",
          value: "Héritage ou donation"
        },
        {
          label: "Vente immobilière",
          value: "Vente immobilière"
        },
        {
          label: "Autre",
          value: "Autre"
        }
      ]
    },
    {
      key: 'fortuneImposable',
      titre: 'Fortune imposable',
      question: "Fortune imposable",
      responses: [
        {
          label: "jusqu'à CHF 100'000",
          value: "jusqu'à CHF 100'000"
        },
        {
          label: "CHF 100' - 200'000",
          value: "CHF 100' - 200'000"
        },
        {
          label: "Plus de CHF 200'000",
          value: "Plus de CHF 200'000"
        }
      ]
    },
    {
      key: 'propietairelogement',
      titre: 'Propiétaire de votre logement',
      question: "Êtes-vous propiétaire de votre logement",
      responses: [
        {
          label: "OUI",
          value: "OUI"
        },
        {
          label: "NON",
          value: "NON"
        }
      ]
    },
    {
      key: 'poursuites',
      titre: 'Poursuites',
      question: "Êtes-vous aux poursuites",
      responses: [
        {
          label: "OUI",
          value: "OUI"
        },
        {
          label: "NON",
          value: "NON"
        }
      ]
    },
    {
      key: 'raisonduchoixcea',
      titre: 'Raison du choix CEA',
      question: "Raison du choix de notre établissement",
      responses: [
        {
          label: "Recommandation",
          value: "Recommandation"
        },
        {
          label: "Publicité",
          value: "Publicité"
        },
        {
          label: "Moteur de recherche",
          value: "Moteur de recherche"
        },
        {
          label: "Autre -> Cliquez pour ouvrir un champs de saisie",
          value: "AutreChoix"
        }
      ]
    }
  ]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [employee, setEmployee ] = useState({});
  const [independant, setIndependant] = useState({});	

  const [choixcea, setChoixcea] = useState({}); 
  const [autrechoix, setAutrechoix] = useState(false);

  const [form] = Form.useForm();
  const [subQuestion, setSubQuestion] = useState('');
  const [activeFonds, setActiveFonds] = useState('');
  const [accepter, setAccepter] = useState(false);
  const [formLayout, setFormLayout] = useState('horizontal');


  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  const buttonItemLayout =
    formLayout === 'horizontal'
      ? {
          wrapperCol: { span: 14, offset: 4 },
        }
      : null;

  useEffect(() => {
    setCurrentQuestion(questions[0]);
  }, [])

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  const handleAnswer = (answer, messageInfo = null) => {
    let reponses = {
      ...questionnaireEconomiqueReponse
    };
  
    if (questions[currentQuestionIndex]?.key === 'raisonduchoixcea' && answer === 'AutreChoix') {

      setSubQuestion('autrechoix');
    } else {
      reponses[currentQuestion.key] = answer;
      setQuestionnaireEconomiqueReponse([
        ...questionnaireEconomiqueReponse,
        {
          titre: currentQuestion?.titre,
          reponse: answer
        }
      ]);
  
      if (questions[currentQuestionIndex]?.key === 'professionnelle' && answer === 'Employé') {
        setSubQuestion('employe');
      } else if (questions[currentQuestionIndex]?.key === 'professionnelle' && answer === 'Indépendant') {
        setSubQuestion('indépendant');
      } else if (questions[currentQuestionIndex]?.key === 'origineFonds') {
        setAccepter(true);
        setActiveFonds(answer);
      } else if (currentQuestionIndex === questions.length - 1) {
        setCurrentParentIndex(4);
      } else {
        setCurrentQuestion(questions[currentQuestionIndex + 1]);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };
  

  return (
    <div className="questionnaire">
        <div className="questionnaire-step">
          <Steps 
            current={currentQuestionIndex}
            progressDot
            direction="vertical"
          >
              {questions.map(item => (
                  <Steps.Step key={item.titre} title={item.titre} />
              ))}
          </Steps>
        </div>
        <div className="steps-content questionnaire-economique-content">
          {currentQuestion && (
            <h2>{currentQuestion.question}</h2>
          )}
          
          <div>
            {
              subQuestion == '' &&
              currentQuestion?.responses?.map((item, index) => (
                <div key={index}>
                  <Button disabled={accepter} className={questions[currentQuestionIndex]?.key == 'origineFonds' && item.value == activeFonds ? 'active' : ''} onClick={() => handleAnswer(item.value)}> {item.label} </Button>
                </div>
              ))
            }
            
            {
              questions[currentQuestionIndex]?.key == 'origineFonds' &&
              <div style={{padding: "20px"}}>
                <Checkbox disabled={!accepter} onChange={() => {
                  setCurrentQuestion(questions[currentQuestionIndex + 1]);
                  setCurrentQuestionIndex(currentQuestionIndex + 1);
                  setAccepter(false)
                }}>
                  Je confirme être l’ayant droit économique
                  <Tooltip placement="bottom" title={"L'ayant doit économique est personne à qui appartiennent les valeurs patrimoniales ou les revenus en découlant. Quiconque agit pour le compte d'une autre personne n'est pas l'ayant droit économique"}>
                    <span> (?)</span>
                  </Tooltip>
                </Checkbox>
              </div>
            }

            { subQuestion == 'autrechoix' && (
              <div className="normal-form">
                <Form.Item label="Autre">
                  <Input
                    onChange={(e) => {
                      setChoixcea({
                        ...choixcea,
                        autreChoix: e.target.value
                      }) ;
                    }} placeholder="Autre"
                  />
                </Form.Item>
                <Form.Item>
                  <Button onClick={() => handleAnswer(choixcea.autreChoix)} disabled={!(choixcea.autreChoix)} type="primary"
                  >
                    Valider
                  </Button>
                  
                </Form.Item>
              </div>
            )}

            {
              subQuestion == 'employe' &&
              <div className="normal-form">
                
                <Form.Item label="Profession">
                  <Input onChange={(e) => {
                    setEmployee({
                      ...employee,
                      profession: e.target.value
                    })
                  }} placeholder="Profession" />
                </Form.Item>
                <Form.Item label="Employeur">
                  <Input onChange={(e) => {
                    setEmployee({
                      ...employee,
                      employeur: e.target.value
                    })
                  }} placeholder="Employeur" />
                </Form.Item>
                <Form.Item label="Pays d'activité">
                  <Select
                    showSearch
                    placeholder="Selectionnez un pays"
                    optionFilterProp="children"
                    value={employee.pays}
                    onChange={(e) => setEmployee({
                      ...employee,
                      pays: e
                    })}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={COUNTRIES.map((country) => {
                      return {
                        label: `${country?.flag} ${country?.name}`,
                        value: country?.name
                      }
                    })}
                  />
                </Form.Item>
                <Form.Item >
                  <Button onClick={() => {
                    setQuestionnaireEconomiqueReponse([
                      ...questionnaireEconomiqueReponse,
                      {
                        titre: 'Profession',
                        reponse: employee.profession
                      },
                      {
                        titre: 'Employeur',
                        reponse: employee.employeur
                      },
                      {
                        titre: "Pays d'activité",
                        reponse: employee.pays
                      }
                    ])
                    setCurrentQuestion(questions[currentQuestionIndex + 1]);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                    setSubQuestion('')
                  } } disabled={!(employee.profession && employee.employeur && employee.pays)} type="primary">Valider</Button>
                </Form.Item>
              </div>
            }
            {
              subQuestion == 'indépendant' &&
              <div className="normal-form">
                
                <Form.Item label="Profession">
                  <Input onChange={(e) => {
                    setIndependant({
                      ...independant,
                      profession: e.target.value
                    })
                  }} placeholder="Profession" />
                </Form.Item>
                <Form.Item label="Pays d'activité">
                  <Select
                    showSearch
                    placeholder="Selectionnez un pays"
                    optionFilterProp="children"
                    value={independant.pays}
                    onChange={(e) => setIndependant({
                      ...independant,
                      pays: e
                    })}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={COUNTRIES.map((country) => {
                      return {
                        label: `${country?.flag} ${country?.name}`,
                        value: country?.name
                      }
                    })}
                  />
                </Form.Item>
                <Form.Item >
                  <Button onClick={() => {
                    setQuestionnaireEconomiqueReponse([
                      ...questionnaireEconomiqueReponse,
                      {
                        titre: 'Profession',
                        reponse: independant.profession
                      },
                      {
                        titre: "Pays d'activité",
                        reponse: independant.pays
                      }
                    ])
                    setCurrentQuestion(questions[currentQuestionIndex + 1]);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                    setSubQuestion('')
                  } } disabled={!(independant.profession && independant.pays)} type="primary">Valider</Button>
                </Form.Item>
              </div>
            }
          </div>
          
        </div>
            
    </div>
  );
}

export default QuestionEconomique;
import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import BanqueArticle from '../LaBanque/BanqueArticle'
import Section from '../../Article/Section'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import ShowInfo from './ShowInfo';

const Information = ({ location} ) => {
const { content } = location.state;

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])
    
    return (
        <Fragment>
            <Header />
            <ShowInfo  info={content}/>
            <Footer />
            <Copyright />
        </Fragment>
    )
}

export default Information;
import React, { useEffect } from 'react'
import Img1 from '../../assets/images/Img1.png'
import Slide2 from '../../assets/images/Slide2.png'
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const SLIDERS = gql`
    query {
        allSliders {
            id
            Titre
            ContenuBouton
            Link
            Description
            Image {
                filename
            }
            pdf {
                filename
            }
            Article {
                id
            }
        }
    }
`;

function Slide() {

    const { loading, error, data } = useQuery(SLIDERS, {
        onCompleted: (data) => {
            if (document.getElementById('scriptCharged')) {
                document.getElementById('scriptCharged').remove()
            }
            const node2 = document.createElement('script');
            node2.src = '/js/slideScript.js';
            node2.type = 'text/javascript';
            node2.id = 'scriptCharged'
            document.getElementById('body').appendChild(node2);
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="slide-home">
            {
                data && data.allSliders ? data.allSliders.map((slider) => (
                    <div key={slider.id} className="content-slides">
                        <div className="images">
                            <img src={slider.Image ? '/uploads/' + slider.Image.filename : ''} alt={slider.Image ? slider.Image.filename : ''} />
                        </div>
                        <div className="content-text">
                            <div className="container">
                                <div className="side-slide">
                                    <h3>{slider.Titre}</h3>
                                    <p>{slider.Description}</p>
                                    {
                                        (slider.Link || (slider.pdf && slider.pdf.filename && slider.pdf.filename !== '')) &&
                                        <Link className="link-decouvrir" target={!slider.Link ? "_blank" : '_self'} to={slider.Link ? slider.Link : ('uploads/' + slider.pdf.filename)}>{slider.Link ? (slider.ContenuBouton ? slider.ContenuBouton : 'Voir détail') : 'Voir le document'}</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''
            }
        </div>
    )
}

export default Slide

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function BottomImageDiv() {
    return (
        <Fragment>
            <div className="end_div">
                <p>La Caisse d’épargne d’Aubonne, <br /> toujours à votre écoute.</p>
                <a href="mailto:Info@ceanet.ch">Laisser un avis</a>
            </div>


        </Fragment>
    )
}

export default BottomImageDiv

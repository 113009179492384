import React, { Fragment, useState } from 'react'
import AsideContentAide from '../../Aside/AsideContentAide'
import Famille from '../../../assets/images/header_aidefaq.jpg'
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "AIDEFAQ"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const AIDEFAQ = gql`
  query {
      allCategorieAideFaqs {
          id
          Nom
          AideFaqs (
                sortBy: Titre_DESC
              ){
              id
              Titre
              Sections {
                  id
                  Titre
                  Contenu
                  Fichier {
                      id
                      Titre
                      Fichier{
                        filename
                      }
                  }
              }
          }
      }
  }
`;

function AideFaqActualite() {
    const [currentCollapse, setCollapse] = useState('');
    const { loading: loadingAIDEFAQ, error: errorAIDEFAQ, data: dataAIDEFAQ } = useQuery(AIDEFAQ, {
        onCompleted: (data) => {
            if(window.location.hash){
                console.log(window.location.hash.split('#')[1])
                var ele = document.getElementById(window.location.hash.split('#')[1]);
                window.scrollTo(ele.offsetLeft,ele.offsetTop - 100);
            }
        }
    });
    const { loading, error, data } = useQuery(ACCROCHES);

    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    // if (loadingAIDEFAQ) return <p>Loading...</p>;
    if (errorAIDEFAQ) return <p>Error :(</p>;

        const addCollapse = (id) => {
            if(currentCollapse.includes(id)) {
                setCollapse(currentCollapse.filter((e)=>(e !== id)));
            } else {
                setCollapse([...currentCollapse, id ]);
            }
        }

    return (
        <Fragment>

            <div className="slide-home">
                <div className="content-slides">
                    <div className="images">
                        <img src={Famille} alt="Ceanet" />
                    </div>
                </div>
            </div>
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                    <div key={accroche.id} className="col-md-12 info-home">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="info-contact info-one-column">
                                        <h1>{accroche.Titre}</h1>
                                        <p>{accroche.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )): ''
            }
            <div id="main-wrapper" className="col-md-12 pages-faq">
                <div className="container">
                    <div className="side-left">
                    <div id="credit-card" className="urgence-div">
                        <h2>Numéros d'urgence</h2>
                        <ul>
                            <li>Centrale de blocage des cartes Debit Mastercard  <span className="first-between-span">+41(0)21 821 12 64</span> </li>

                            <li>Centrale de blocage des cartes Cornercard <span >+41(0)91 800 41 41</span>  </li>

                            <li>Centrale de blocage des cartes Viseca : <span className="between-span">+41(0)58 958 83 83</span></li>
                        </ul>
                        <p>Contactez directement la banque durant les heures d’ouverture.</p>
                    </div>
                    <h2 style={{padding:" 44px 50px 0px"}}>FAQ</h2>
                        {
                            dataAIDEFAQ && dataAIDEFAQ.allCategorieAideFaqs ? dataAIDEFAQ.allCategorieAideFaqs.map((categorie) => (
                                <div id="e-banking" className="collapsed-row" key={categorie.id}>
                                    <h2>{categorie.Nom}</h2>

                                    {
                                        categorie && categorie.AideFaqs ? categorie.AideFaqs.map((aidefaq) => (
                                            <div key={aidefaq.id}>
                                                <h3 onClick={() => { addCollapse(aidefaq.id) }} className={currentCollapse.includes(aidefaq.id) ? "collapsible  active" : "collapsible"}>
                                                    <span className="aide_content_title">{aidefaq.Titre}</span>
                                                </h3>
                                                <div className="content-collapse" style={{ display: currentCollapse.includes(aidefaq.id) ? 'block' : 'none' }}>

                                                    {
                                                        aidefaq && aidefaq.Sections ? aidefaq.Sections.map((section) => (
                                                            <div key={section.id}>

                                                                <div className="prghe_" dangerouslySetInnerHTML={{__html: section.Contenu ? section.Contenu : ''}}></div>

                                                              {
                                                                section && section.Fichier && section.Fichier.map(fichier => (
                                                                  <p>
                                                                <a className="link-pdf" key={fichier.id} href={'uploads/' + fichier.Fichier.filename} target="_blank">{fichier.Titre}</a>
                                                                </p>
                                                                ))
                                                              }

                                                          </div>
                                                        )) : ''
                                                    }
                                                </div>

                                            </div>
                                        )) : ''
                                    }
                                </div>

                            )) : ''
                        }
        <div className="mentionleg">
            <h2>Mentions légales</h2>
            <Link className="mention-link" to="/Conditions"> <span>Veuillez découvrir nos mentions légales</span></Link>
        </div>
                    </div>
                    <AsideContentAide />
                </div>
            </div>
        </Fragment >
    )
}

export default AideFaqActualite

import React from 'react'
import AsideContentActualite from '../Aside/AsideContentActualite'
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const BRIEFING_OPTIONS = gql`
    query {
        allPages (
            where: {
                BriefingOptions_every: {
                    PageCurrant: {
                        Nom: "ACCUEIL"
                    }
                }
            }
        ){
      id
      Nom
      BriefingOptions {
        id
        Titre
        PageCurrant {
          id
          Nom
          Lien
        }
        PageRedirection {
          id
          Nom
          Lien
        }
        Image {
          id
          filename
        }
        Contenu
      }
    }
  }
`;

function Article() {
    const { loading, error, data } = useQuery(BRIEFING_OPTIONS);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div id="main-wrapper" className="col-md-12">
            <div className="container">
                <div className="side-left">
                    {
                        data && data.allPages && data.allPages[0] && data.allPages[0].BriefingOptions ? data.allPages[0].BriefingOptions.map((briefing) => (
                            <div key={briefing.id} className="bloc-info">
                                <img src={briefing.Image ? 'uploads/' + briefing.Image.filename : ''} alt={briefing.Image.filename} />
                                <div className="info">
                                    <h2>{briefing.Titre}</h2>
                                    <div className="desc" dangerouslySetInnerHTML={{ __html: briefing.Contenu }}></div>
                                    <Link to={briefing.PageRedirection ? briefing.PageRedirection.Lien : ''}>Découvrir les options</Link>
                                </div>
                            </div>
                        )) : ''
                    }
                </div>
                <AsideContentActualite />
            </div>
        </div>
    )
}

export default Article

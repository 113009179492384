import React, { Fragment,useState } from 'react'
import AsideContentActualite from '../../Aside/AsideContentActualite'
import Proprietaire from '../../../assets/images/header_labanque.jpg'
import { useQuery, gql } from '@apollo/client';

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "LABANQUE"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const BRIEFING_OPTIONS = gql`
    query {
        allPages (
            where: {
                Nom: "LABANQUE"
                BriefingOptions_every: {
                    PageCurrant: {
                        Nom: "LABANQUE"
                    }
                }
            }
        ){
      id
      Nom
      BriefingOptions {
        id
        Titre
        PageCurrant {
          id
          Nom
          Lien
        }
        PageRedirection {
          id
          Nom
          Lien
        }
        Image {
          id
          filename
        }
        Contenu
      }
    }
  }
`;

const DIRECTIONS = gql`
    query {
        allDirections {
            id
            Nom
            Fonction
            Commentaire
        }
  }
`;
const CONSEILADMIN = gql`
    query {
        allConseilAdministrations (
        first: 20
        ) {
            id
            Nom
            Commentaire
        }
  }
`;

function BanqueArticle() {

    const { loading, error, data } = useQuery(ACCROCHES);
    const { loading: loadingBriefing, error: errorBriefing, data: dataBriefing } = useQuery(BRIEFING_OPTIONS);
    const { loading: loadingDirection, error: errorDirection, data: dataDirection } = useQuery(DIRECTIONS);
    const { loading: loadingConseilAdministration, error: errorConseilAdministration, data: dataConseilAdministration } = useQuery(CONSEILADMIN );

    const [currentCollapse, setCurrentCollapse] = useState([])
    const addCollapse = (id) => {
        if(currentCollapse.includes(id)) {
            setCurrentCollapse(currentCollapse.filter((e)=>(e !== id)));
        } else {
            setCurrentCollapse([...currentCollapse, id ]);
        }
    }
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (loadingBriefing) return <p>Loading...</p>;
    if (errorBriefing) return <p>Error :(</p>;

    if (loadingDirection) return <p>Loading...</p>;
    if (errorDirection) return <p>Error :(</p>;

    if(loadingConseilAdministration)  return<p>Loading...</p>
    if(errorConseilAdministration)  return<p>Loading...</p>


    return (
        <Fragment>
            <div className="slide-home">
                <div className="content-slides">
                    <div className="images">
                        <img src={Proprietaire} alt="Ceanet" />
                    </div>
                </div>
            </div>
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                       <div key={accroche.id} className="col-md-12 info-home">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="info-contact info-one-column">
                                        <h1>{accroche.Titre}</h1>
                                        <p>{accroche.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )): ''
            }
            <div id="main-wrapper" className="col-md-12">
                <div className="container page-banque ">
                    <div className="side-left">
                      <div className="elmt-banidques">
                        {
                            dataBriefing && dataBriefing.allPages && dataBriefing.allPages[0] && dataBriefing.allPages[0].BriefingOptions ? dataBriefing.allPages[0].BriefingOptions.map((briefing, index) => (
                              <div className="bloc_credit bloc-info" key={briefing.id}>
                                <img src={briefing.Image ? 'uploads/' + briefing.Image.filename : ''} alt={briefing.Image.filename} alt="" />
                                <div className="info">
                                    <h2>{briefing.Titre}</h2>
                                    <div className="desc" dangerouslySetInnerHTML={{ __html: briefing.Contenu}}></div>
                                </div>


                              </div>

                            )) : ''
                        }
                      </div>

                        <div className="direction wrapper-conditions">
                          <div className="collapsed-row">
                            <div className="direction_title">
                                <h2>Conseil d'administration</h2>
                            </div>
                            {
                                data && dataConseilAdministration.allConseilAdministrations ? dataConseilAdministration.allConseilAdministrations.map((conseilAdmin) => (
                                    <div key={conseilAdmin.id} className="left_">
                                        <h3  onClick={() => { addCollapse(conseilAdmin.id) }} className={currentCollapse.includes(conseilAdmin.id) ? "collapsible  active" : "collapsible"} >
                                          <span className="aide_content_title">{conseilAdmin.Nom}</span>
                                        </h3>
                                        <div className={currentCollapse.includes(conseilAdmin.id) ? "content-collapse show-bloc" : "content-collapse"} >
                                        <p>
                                            {conseilAdmin.Commentaire}
                                        </p>
                                      </div>
                                    </div>
                                )) : ''
                            }

                            <div style={{clear:"both"}}></div>
                            <div className="direction_title">
                                <h2>Direction</h2>
                            </div>
                                                       {
                                dataConseilAdministration && dataDirection.allDirections ? dataDirection.allDirections.map((direction) => (
                                    <div key={direction.id} className="left_">
                                        <h3  onClick={() => { addCollapse(direction.id) }} className={currentCollapse.includes(direction.id) ? "collapsible  active" : "collapsible"}>
                                          <span className="aide_content_title">{direction.Nom}</span>
                                          <span className="aide_content_title">{direction.Fonction}</span>
                                        </h3>
                                        <div className={currentCollapse.includes(direction.id) ? "content-collapse show-bloc" : "content-collapse"}>
                                        <p>
                                            {direction.Commentaire}
                                        </p>
                                    
                                    </div>
                                    </div>
                                )) : ''
                            }
                          </div>
                        </div>
                    </div>
                    <AsideContentActualite />
                </div>
            </div>
        </Fragment>
    )
}

export default BanqueArticle

import React, { Fragment, Component } from "react";
import { Schema } from "@fieldify/antd";
import "@fieldify/antd/dist/index.css";
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import { DatePicker, Space, Checkbox, Select } from 'antd';
import 'react-phone-number-input/style.css'
import COUNTRIES from './countries.json';
import moment from 'moment';
import 'moment/locale/fr';


const { FieldifySchemaForm } = Schema;
const dateFormat = 'DD.MM.YYYY';

class ContactInputFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: "vertical",
      schema: {},
    };
  }
  formChanged(input, value) {
    // run the verifier on each change
    input.verify((result) => {
      // console.log('result : ',result);
      const state = {
        input: { ...result.result },
        error: result.error,
      };

      if (result.error === true) {
        // form verification failed
      } else {
        // form verification success
      }

      this.setState(state);
      this.props.onChange.setInput({ input: this.state.input, error: this.state.error });
    });
  }

  componentDidMount() {
    this.setState({ schema: this.props.schema, input: this.props.input });
  }

  onFinish = values => {
      console.log(values)
  }

  onCountryChange(value) {
    console.log(`selected ${value}`);
  };

  render() {
    return (
      <Fragment>
        <div class="form_content">
          <div class="divider_2"></div>
          <div class="text_position">
            <h2 style={{ paddingBottom: "20px" }}>
              Données personnelles
            </h2>
            <p>
              Afin de pouvoir traiter votre requête, nous allons recueillir certaines données personnelles dans un but de pré-enregistrement. Nous allons vous recontacter sous peu afin de confirmer votre demande.
            </p>
          </div>
          <div class="divider_2"></div>

          <div>


          <ConfigProvider locale={frFR}  format={dateFormat}
           >
          <FieldifySchemaForm
              schema={this.props.schema}
              input={this.props.input}
              layout={this.state.layout}
              onChange={this.formChanged.bind(this)}
         
            />

            </ConfigProvider>
            <Space direction="vertical" className="dateofbirth">
              <h3>Date de naissance</h3>
               <ConfigProvider locale={frFR}>
                <DatePicker locale={frFR} /*defaultValue={moment('01.01.2020',dateFormat)}*/ placeholder={'jj.mm.aaaa'} format={dateFormat} onChange={(e)=> this.props.onChange.setDateofbirth({...this.props.dateofbirth, date:e._d})} />
              </ConfigProvider>
            </Space>

            {
              this.props.OIDprocess == 'OUI' &&
              <Space direction="vertical" className="contryofbirth">
                <h3>Nationalité</h3>
                <Select
                  showSearch
                  placeholder="Selectionnez un pays"
                  optionFilterProp="children"
                  value={this.props.pays}
                  onChange={(e) => this.props.setPays(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={COUNTRIES.map((country) => {
                    return {
                      label: `${country?.flag} ${country?.name}`,
                      value: country?.name
                    }
                  })}
                />
              </Space>
            }

            {
              this.props.OIDprocess == 'OUI' &&
              <Space direction="vertical" className="contryofbirth etat-civil">
                <h3>Etat civil</h3>
                <Select
                  showSearch
                  placeholder="Selectionnez un pays"
                  optionFilterProp="children"
                  value={this.props.etatCivil}
                  onChange={(e) => this.props.setEtatCivil(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={
                    [
                      {
                        label: 'Marié(e)',
                        value: 'Marié'
                      },
                      {
                        label: 'Veuf - Veuve',
                        value: 'Veuf'
                      },
                      {
                        label: 'Célibataire',
                        value: 'Célibataire'
                      },
                      {
                        label: 'Divorcé(e)',
                        value: 'Divorcé'
                      }
                    ]
                  }
                />
              </Space>
            }

            {/* <Space direction="vertical" className="contryofbirth">
              <h3>Situation professionnelle</h3>
              <Select
                showSearch
                placeholder="Selectionnez votre situation professionnelle"
                optionFilterProp="children"
                value={this.props.etatCivil}
                onChange={(e) => this.props.setEtatCivil(e)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                  [
                    {
                      label: 'Employé',
                      value: 'Employé'
                    },
                    {
                      label: 'Indépendant',
                      value: 'Indépendant'
                    },
                    {
                      label: 'Retraité',
                      value: 'Retraité'
                    },
                    {
                      label: 'En formation',
                      value: 'En formation'
                    },
                    {
                      label: 'Sans activité lucrative',
                      value: 'Sans activité lucrative'
                    }
                  ]
                }
              />
            </Space>

            <Space direction="vertical" className="contryofbirth">
              <h3>Autres éléments économiques</h3>
              <Select
                showSearch
                placeholder="Selectionnez votre situation professionnelle"
                optionFilterProp="children"
                value={this.props.etatCivil}
                onChange={(e) => this.props.setEtatCivil(e)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                  [
                    {
                      label: 'Employé',
                      value: 'Employé'
                    },
                    {
                      label: 'Indépendant',
                      value: 'Indépendant'
                    },
                    {
                      label: 'Retraité',
                      value: 'Retraité'
                    },
                    {
                      label: 'En formation',
                      value: 'En formation'
                    },
                    {
                      label: 'Sans activité lucrative',
                      value: 'Sans activité lucrative'
                    }
                  ]
                }
              />
            </Space>

            <Space style={{paddingTop: '20px', clear: 'both'}} direction="vertical" className="contryofbirth">
              <h3>Revenu brut annuel</h3>
              <Select
                showSearch
                placeholder="Selectionnez votre revenu brut annuel"
                optionFilterProp="children"
                value={this.props.etatCivil}
                onChange={(e) => this.props.setEtatCivil(e)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                  [
                    {
                      label: "Jusqu'à CHF 40'000",
                      value: "Jusqu'à CHF 40'000"
                    },
                    {
                      label: "CHF 40' - 100'000",
                      value: "CHF 40' - 100'000"
                    },
                    {
                      label: "CHF 100' - 150'000",
                      value: "CHF 100' - 150'000"
                    },
                    {
                      label: "Plus de CHF 150'000",
                      value: "Plus de CHF 150'000"
                    }
                  ]
                }
              />
            </Space>

            <Space style={{paddingTop: '20px'}} direction="vertical" className="contryofbirth">
              <h3>Origine de la fortune principale</h3>
              <Select
                showSearch
                placeholder="Origine de la fortune principale"
                optionFilterProp="children"
                value={this.props.etatCivil}
                onChange={(e) => this.props.setEtatCivil(e)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                  [
                    {
                      label: 'Salaire / Revenu',
                      value: 'Salaire / Revenu'
                    },
                    {
                      label: 'Héritage / Donation',
                      value: 'Héritage / Donation'
                    },
                    {
                      label: "Profits d'investissement",
                      value: "Profits d'investissement"
                    },
                    {
                      label: 'Vente du commerce',
                      value: 'Vente du commerce'
                    },
                    {
                      label: 'Vente de bien immbiliers',
                      value: 'Vente de bien immbiliers'
                    },
                    {
                      label: 'Gains de loterie / jeux',
                      value: 'Gains de loterie / jeux'
                    },
                    {
                      label: 'Autres',
                      value: 'Autres'
                    }
                  ]
                }
              />
            </Space>

            <Space style={{paddingTop: '20px', clear: 'both'}} direction="vertical" className="contryofbirth">
              <h3>Revenu brut annuel</h3>
              <Select
                showSearch
                placeholder="Selectionnez votre revenu brut annuel"
                optionFilterProp="children"
                value={this.props.etatCivil}
                onChange={(e) => this.props.setEtatCivil(e)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                  [
                    {
                      label: "Jusqu'à CHF 40'000",
                      value: "Jusqu'à CHF 40'000"
                    },
                    {
                      label: "CHF 40' - 100'000",
                      value: "CHF 40' - 100'000"
                    },
                    {
                      label: "CHF 100' - 150'000",
                      value: "CHF 100' - 150'000"
                    },
                    {
                      label: "Plus de CHF 150'000",
                      value: "Plus de CHF 150'000"
                    }
                  ]
                }
              />
            </Space>

            <Space style={{paddingTop: '20px'}} direction="vertical" className="contryofbirth">
              <h3>Apports initiaux lors de l'ouverture du compte</h3>
              <Select
                showSearch
                placeholder="Apports initiaux lors de l'ouverture du compte"
                optionFilterProp="children"
                value={this.props.etatCivil}
                onChange={(e) => this.props.setEtatCivil(e)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                  [
                    {
                      label: "jusqu'à CHF 10'000",
                      value: "jusqu'à CHF 10'000"
                    },
                    {
                      label: "CHF 10' - 100'000",
                      value: "CHF 10' - 100'000"
                    },
                    {
                      label: "CHF 100' - 500'000",
                      value: "CHF 100' - 500'000"
                    },
                    {
                      label: "Plus de CHF 500'000",
                      value: "Plus de CHF 500'000"
                    }
                  ]
                }
              />
            </Space> */}
            
        
          </div>
          <div className="remarque-inuputField">
            <div className="remarque-form">
            <label htmlFor="textarea">Remarque</label>
              <textarea value={this.props.remarque} name="textarea" onChange={ (e) => this.props.onChange.setRemarque(e.target.value)}></textarea>
            </div>
        
          </div>
          {
            this.props.OIDprocess == 'OUI' &&
            <div style={{ display: 'block', width: 700, padding: 30 }}>
              <Checkbox defaultChecked={this.props.conditionAccepter} name="condition" onChange={(e)=> { this.props.onChange.setConditionAccepter(e.target.checked) }}>J'accepte les conditions generales du site</Checkbox>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export default ContactInputFields;

import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import ActualiteArticle from './ActualiteArticle'
import { useLocation, Route } from 'react-router-dom';
import DetailsActualite from './DetailsActualite';
import { Helmet } from 'react-helmet';

function Actualites({ location }) {
    let fromHome = false;
    if(location){
        location.state ? fromHome = true : fromHome = false;
    }
    useEffect(() => {
        if(fromHome){
            if (document.getElementById('burgerId')) {
                document.getElementById('burgerId').remove()
            }
            const node = document.createElement('script');
            node.src = '/js/responsive.js';
            node.type = 'text/javascript';
            node.id = 'burgerId'
            document.getElementById('body').appendChild(node);
        }
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }
        }, 500)
    }, []);
    return (

        <Fragment>
          <Helmet>
            <title>Caisse d’épargne d’Aubonne - Actualités</title>
            <meta name="description" content="Découvrez les dernières actualités de la Caisse d’Epargne d’Aubonne ainsi que tous nos conseils pour la gestion de votre argent." />
          </Helmet>
            <Header />
            <SideBtnLink />
            <Route exact path={"/Actualites"} render={() => (
                <ActualiteArticle />
            )} />
            <Route exact path={"/Actualites/:id"} component={DetailsActualite} />
            <Footer />
            <Copyright />
        </Fragment>

    )
}

export default Actualites

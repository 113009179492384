import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

const style = {
    image: {
        height: '400px'
    },
    content: {
        height: '115px',
        marginTop: '1%',
        marginBottom: '1%'
    },
    themeTypes: {
        marginTop: '-40px',
    }
}

const DetailOffre = ({ id, fromHome }) => {
    useEffect(() => {
        if(fromHome){
            if (document.getElementById('burgerId')) {
                document.getElementById('burgerId').remove()
            }
            const node = document.createElement('script');
            node.src = '/js/responsive.js';
            node.type = 'text/javascript';
            node.id = 'burgerId'
            document.getElementById('body').appendChild(node);
        }
    }, []);

    const detailOffre = gql`
    query {
        Offre (
            where: {
              id: "${id}"
            }
        ) {
        id
        Titre
        Contenu
        Image {
            filename
        }
        }
    }
   `;
    const { loading, error, data } = useQuery(detailOffre);
    return (

        <div className="container">
            {data && data.Offre &&
                <article>
                    <img style={{ width: "100%", marginTop: "2%" }} src={data.Offre.Image ? '../uploads/' + data.Offre.Image.filename : ''} alt={data.Offre.Image ? data.Offre.Image.filename : ''} />
                    <div className="content">
                        <h3 className="titre-detail-offre" style={{ color: "#1b327e", fontSize: "25px", paddingTop: "1%" }}> {data.Offre.Titre}</h3>
                        <div style={style.content} dangerouslySetInnerHTML={{ __html: data.Offre.Contenu }} />
                    </div>

                </article>
            }

        </div>
    );
};

export default DetailOffre;

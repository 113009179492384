import React, { Component,useState } from 'react'
import { DatePicker, Space } from 'antd';
import { Select } from 'antd';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';

const { Option } = Select;
const dateFormat = 'DD.MM.YYYY';

 class ChoixDateRDV extends Component {



    handleDate = (date, dateString) => {
        console.log(date, dateString);
    }


    handleChange = (value) => {
        console.log(`selected ${value}`);

      }
    render() {

        return (
            <>
                <div class="divider_2"></div>
                <h2>Prise de rendez-vous</h2>
                <p style={{ color: '#848484' }}>Cette page vous permet de choisir la date qui vous convient le mieux afin de planifier votre rendez-vous avec la CEA</p>
                <div class="divider_2"></div>

                <div style={{overflow:"hidden", margin:"0 0 182px"}}>

                    <div style={{ width: "50%", float: "left" }} class="bloc">
                        <h2>Choix de la date</h2>
                        <Space direction="vertical">
                        <ConfigProvider locale={frFR}>
                        <DatePicker locale={frFR} defaultValue={moment('01.01.2020',dateFormat)} format={dateFormat} onChange={(e)=> this.props.setRdv({...this.props.rdv, date:e._d})} />
                            </ConfigProvider>
                        </Space>,
                </div>
                    <div style={{ width: "50%", float: "left" }} class="bloc bloc-heure">
                        <h2>Choix de l'heure</h2>
                        <p>Le choix de l'heure est susceptible de changer selon nos disponibilités. Nous vous contacterons par e-mail pour confirmer la date et l'heure.</p>
                        <Select defaultValue="08:00" style={{ width: 120 }}  onChange={(e)=> this.props.setRdv({...this.props.rdv, heure:e})}>
                        <Option value="08:00">08:00</Option>
                        <Option value="09:00">09:00</Option>
                        <Option value="10:00">10:00</Option>
                        <Option value="11:00">11:00</Option>
                        <Option value="l4:00">14:00</Option>
                        <Option value="15:00">15:00</Option>
                        <Option value="16:00">16:00</Option>
                         </Select>


                    </div>

                </div>

            </>
        )
    }
}


export default ChoixDateRDV

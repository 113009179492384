import React from 'react'
import CeaPhone from '../../assets/images/CeaPhone.svg'
import CeaMail from '../../assets/images/CeaMail.svg'
import CeaPlace from '../../assets/images/CeaPlace.svg'
import { Link } from 'react-router-dom'

function SideBtnLink() {
    return (
        <div className="side-contact">
            <h3><Link to="/Contact" > <span style={{ color: '#1b327e' }}>Contact</span></Link></h3>
            <a href="tel:0041218211260"><img src={CeaPhone} alt="Ceanet" /></a>
            <a href=" mailto:info@ceanet.ch"><img src={CeaMail} alt="Ceanet" /></a>
            <Link to="/Contact#mapdivcontact"><img src={CeaPlace} alt="Ceanet" /></Link>
        </div>
    )
}

export default SideBtnLink

import React from 'react';
import 'antd/dist/antd.css';
import { useQuery, gql } from '@apollo/client';

const TableauComparatif = () => {
const COMPTES = gql`
query {
allCategorieComptes {
id
Nom
Comptes {
id
Titre
Taux
SousTitre
Description
Contenu
}
}
}
`;
const { loading, error, data } = useQuery(COMPTES);
if (loading) return <p>Loading...</p>;
if (error) return <p>Error :(</p>;
return (
<div  className="body-popup">
    <h2>Tableau comparatif des comptes </h2>
        <div style={{ background:'#F6F6F6',padding:"40px",margin:"60px 0"}} class="tableaux-comparatif">
      {
                data && data.allCategorieComptes ? data.allCategorieComptes.map((categorie) => (
                    <div key={categorie.id} className="tableau-container">
                        <h2 style={{ padding: "30px 0 30px" }}>{categorie.Nom}</h2>
            <table class="table-responsive">
                <thead>
                    <tr>
                        <th className="first">Nom du compte</th>
                        <th className="second">Taux d’intérêt</th>
                        <th className="last">Conditions</th>
                    </tr>
                </thead>
                <tbody>
                                {
                                    categorie && categorie.Comptes ? categorie.Comptes.map((compte) => (
                                        <tr key={compte.id}>
                                            <td class="first">{compte.Titre}</td>
                                            <td class="second">{compte.Taux}</td>
                                            <td class="last" dangerouslySetInnerHTML={{ __html: compte.Contenu }} ></td>
                                        </tr>
                                    )) : ""
                                }
                 
                </tbody>
            </table>
            </div>
                )) : ''
      }
         
     
    </div>
        <div style={{marginTop: "30px" }} className="tableau-display-mobile">
            {
                data && data.allCategorieComptes ? data.allCategorieComptes.map((categorie) => (
                    <div key={categorie.id} style={{ background: "#F6F6F6", padding: "30px" }} className="typecompte">
                      
                        {
                            categorie && categorie.Comptes ? categorie.Comptes.map((compte) => (
                                <div style={{border:"1px solid gray", marginBottom:"50px"}} key={compte.id}>
                                    <h2 style={{ padding: "20px 0", background: " #FFDE00", textAlign: "center" }}>{compte.Titre}</h2>
                                    <p style={{ padding: " 40px 20px" }}><span style={{ color: "#1B327E" }}>Taux </span>: <span style={{float:"right"}}>{compte.Taux}</span>  </p>
                                    <p style={{ padding: "20px" }}><span style={{ color: "#1B327E" }}>Condtions :</span>
                                        <span  dangerouslySetInnerHTML={{ __html: compte.Contenu }}></span></p>

                                </div>
                            )) : ""
                        }
                    </div>

                )) : ''
            }


        </div>

</div>


);
};

export default TableauComparatif;
import React from 'react';

const ShowInfo = ({ info }) => {
    return (
        <div className="show-info">
            <p>Infomations supplémentaires</p>
            <div className="content">
                {info}
            </div>

        </div>

    );
};

export default ShowInfo;
import React, { Fragment, Component, useState, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client';
import SideBtnLink from '../../Aside/SideBtnLink'
import { useParams } from 'react-router-dom'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import ContactHeaderSlider from './ContactHeaderSlider'
import ContactMainContent from './ContactMainContent'
import Header from '../../Header/Header'
import { Helmet } from 'react-helmet'
import successImage from '../../../assets/images/success.jpeg';

const PRENDRE_RDV = gql`
  mutation prendreRDV($token: String! ) {
    prendreRDV(token: $token)
  }
`;

const DemandeFinish = (props) => {

    const [token, setToken] = useState('')

    useEffect(() => {
        setTimeout(() => {
            if (!window.location.hash) {
                window.scrollTo(0, 0);
            }

        }, 500)
        console.log(props)
        const _token = props.location && props.location.state ? props.location.state.token : (props?.match?.params?.token ? props?.match?.params?.token : '');
        if (_token) {
            setToken(_token);
            prendreRDV({ variables: { token: _token } });
        }
    }, [])

    const [prendreRDV, { data, loading, error }] = useMutation(PRENDRE_RDV, {
        onError(error) {
            console.log('rendez-vous erreur : ', error)
        },
        onCompleted (complete) {
            console.log('Rendez-vous envoyé')
        }
    });

    return (
        <Fragment>
            <Helmet>
                <title>Caisse d’épargne d’Aubonne - Contact - Success</title>
                <meta name="description" content="Pour chaque situation, nous essayons de trouver une solution. Nous sommes à votre écoute pour vous aider dans vos projets success." />
            </Helmet>
            <Header />
            <div className='container'>
                <div className='remerciement'>
                    <img src={successImage} />
                    <p>
                        {
                            token ?
                            "Votre demande d’ouverture de compte a bien été prise en compte. Nous vous contacterons dans un délai de 48h afin de fixer un rendez-vous."
                            :
                            "Merci d’avoir finalisé le processus. Vous allez recevoir un e-mail de confirmation."
                        }
                    </p>
                </div>
            </div>
            <Footer />
            <Copyright />
        </Fragment>

    )


}

export default DemandeFinish
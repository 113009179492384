import React, { useState, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client';
import { Link } from 'react-router-dom'
import { Redirect  } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Steps, Button, message } from 'antd';
import ContactCheckbox from '../Contact/ContactCheckbox'
import ContactCheckboxNext from '../Contact/ContactCheckboxNext'
import ContactInputFields from '../Contact/ContactInputFields'
import AllInfo from '../Contact/AllInfo'
import ContactConfirmation from './ContactConfirmation';
import ChoixDateRDV from './ChoixDateRDV'
import OpenAccount from './OpenAccount';
import QuestionEconomique from './QuestionEconomique';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import COUNTRIES from './countries.json';


// const navigate = useNavigate();

const schema = {
    name: {
        $doc: "Nom",
        $type: "String",
        $position: 0,
        $required: true,
        $options: {
            placeholder: 'Muster',
            unicode: true,
            min: 1
        }
    },
    firstname: {
        $doc: "Prénom",
        $type: "String",
        $required: true,
        $position: 1,
        $options: {
            placeholder: 'Anna',
            unicode: true,
            min: 1
        }
    },
    mail: {
        $doc: "Mail",
        $type: "Email",
        $required: true,
        $position: 3,
        $options: {
            placeholder: 'muster@muster.com',
            min: 1
        }
    },

   phoneNumber: {
    $doc: "Téléphone Mobile",
    $type: "String",
    $required: true,
    $options: {
      placeholder: '+41712672844',
      strict: false,
      unicode: true,
      min: 1
    },
    $read: true,
    $write: true,
    $position: 4
  },
   /*  dateofbirth: {
        $doc: "Date de naissance",
        $type: "DatePicker",
        $position: 4,
        $required: true,
        $options: {
            placeholder: 'JJ/MM/AAAA',
            min: 10,
            max: 10
        } */

    home: {
        $doc: "Adresse",
        $type: "String",
        $required: true,
        $read: true,
        $write: true,
        $position: 2,
        country: {
            $doc: "",
            $type: "String",
            $read: true,
            $write: true,
            $required: true,
            $position: 4,
            $options: {
                placeholder: 'Ville',
                unicode: true,
                min: 1
            }

        },
        street: {
            $doc: "",
            $type: "String",
            $required: true,
            $options: {
                placeholder: "Rue",
                unicode: true,
                min: 1
            },
            $read: true,
            $write: true,
            $position: 1
        },
        street_number: {
            $doc: "",
            $type: "String",
            $required: true,
            $options: {
                placeholder: "N°",
                unicode: true,
                min: 1
            },
            $read: true,
            $write: true,
            $position: 2
        },
         zip: {
            $doc: "",
            $type: "String",
            $read: true,
            $required: true,
            $write: true,
            $position: 3,
            $options: {
                placeholder: 'Code postal',
                unicode: true,
                min: 1
            }
        }

    }
}

// const SEND_MAIL = gql`
//   mutation Send($firstname: String!, $name: String!, $email: String!, $dateofbirth: String!, $street: String!, $zip: String!, $country: String!, $compte: String!, $credit: String!, $divers: String!, $compteCourant: String!, $compteEpargne: String!, $comptePrevoyance: String!, $obligationCaisse: String!) {
//     send(firstname: $firstname, name: $name, email: $email, dateofbirth: $dateofbirth, street: $street, zip: $zip, country: $country, compte: $compte, credit: $credit, divers: $divers, compteCourant: $compteCourant, compteEpargne: $compteEpargne, comptePrevoyance: $comptePrevoyance, obligationCaisse: $obligationCaisse)
//   }
// `;

const SEND_MAIL = gql`
  mutation Send($firstname: String!, $name: String!, $email: String!,$phoneNumber: String!, $dateofbirth: String!, $street: String!, $street_number: String!, $zip: String!, $country: String!, $compte: String!, $compteCourant: String!, $compteEpargne: String!, $comptePrevoyance: String!, $obligationCaisse: String!, $remarque: String!, $date: String!, $heure: String!, $OIDprocess: String!, $pays: String!, $paysCode: String!, $etatCivil: String!, $questionnaireEconomiqueReponse: String! ) {
    send(firstname: $firstname, name: $name, email: $email,phoneNumber: $phoneNumber, dateofbirth: $dateofbirth, street: $street, street_number: $street_number, zip: $zip, country: $country, compte: $compte, compteCourant: $compteCourant, compteEpargne: $compteEpargne, comptePrevoyance: $comptePrevoyance, obligationCaisse: $obligationCaisse, remarque: $remarque, date: $date, heure: $heure, OIDprocess: $OIDprocess, pays: $pays, paysCode: $paysCode, etatCivil: $etatCivil, questionnaireEconomiqueReponse: $questionnaireEconomiqueReponse)
  }
`;

const ProgressBar = ({subscription}) => {

    const [reCaptcha, setRecaptCha] = useState(false);
    const [showStepContent, setShowStepContent] = useState(true);
    const [loadingSendEmail, setLoadingSendEmail] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [current, setCurrent] = useState(0);
    const [input, setInput] = useState({ input: {}, error: true });
    const [inputCheckbox, setInputCheckbox] = useState({ input: {}, error: true });

    const [OIDprocess, setOIDprocess] = useState('NON');

    const [inputCheckboxNext, setInputCheckboxNext] = useState({ input: {}, error: true });
    const [steps, setSteps] = useState([]);
    const [rdv,setRdv] = useState({})
    const [remarque, setRemarque] =useState('');
    const [conditionAccepter, setConditionAccepter] = useState(false);
    const [dateofbirth, setDateofbirth] = useState({})
    const [next,setNext] = useState(true)
    const [lienOID, setLienOID] = useState('');
    const [questionnaireReponse, setQuestionnaireReponse] = useState({})
    const [isComptePrevObltodisabled, setIsComptePrevObltodisabled] = useState(false);
    const [questionnaireFalse, setQuestionnaireFalse] = useState(false);
    const [messageErrorQuestionnaire, setMessageErrorQuestionnaire] = useState('');
    const [pays, setPays] = useState('');
    const [etatCivil, setEtatCivil] = useState('');
    const [questionnaireEconomiqueReponse, setQuestionnaireEconomiqueReponse] = useState([]);

    const handleValidate = (value) => {
        const isValid = isValidPhoneNumber(value);
        console.log({ isValid })
        return isValid

        
    }

    const isToDisable = () => {
        let result = true;
        switch(current){
            case 0 :
            (inputCheckbox && inputCheckbox.input && inputCheckbox.input.compte && inputCheckbox.input.compte !== "") ? result = false : result = true
            break;
            case 1 :
                (inputCheckboxNext.input.compteCourant || inputCheckboxNext.input.compteEpargne || inputCheckboxNext.input.comptePrevoyance || inputCheckboxNext.input.obligationCaisse ) ? result = false : result = true
            break;
            case 4 :
                (!input.error && dateofbirth.date && ((conditionAccepter && OIDprocess == 'OUI' && pays && etatCivil) || OIDprocess == 'NON') ) ? result = false : result = true
            break;
            case 5 :
                (rdv) ? result = false : result = true
            break;


            default :
                console.log(result)
            break;
        }

        return result
    }


    const [sendMail, { data, loading, error }] = useMutation(SEND_MAIL, {
        onError(error) {
            setMailError(true);
            console.log('sendMail erreur : ', error)
            setLoadingSendEmail(false);
            message.error('Un probléme est survenu. Veuillez nous contacter. Merci de votre interêt pour la CEA.');
            gototop();
        },
        onCompleted (complete) {
            if (OIDprocess == 'OUI') {
                setLienOID(complete?.send);
                
                setMailError(false);
                setLoadingSendEmail(false);
            }
            else {
                setMailError(false);
                setLoadingSendEmail(false);
                message.success('Requête prise en compte. Un administrateur traitra votre demande!');
            }
            gototop();
        }
    });

    const gototop = () => {
        window.scrollTo({ top: 300, behavior: 'smooth' })
    }
 
    const oIDprocess = () => {
        setCurrent(2);
        setOIDprocess('OUI');
    }


    useEffect(() => {
        /*setTimeout(() => {
            setLienOID('https://beta.oid.swisscom.ch/action/adeb9329b8')
        }, 3000)*/
        setSteps([
            {
                title: '',
                content: <ContactCheckbox input={inputCheckbox ? inputCheckbox : {}} onChange={setInputCheckbox} next={next} setNext={setNext} />,
            },
            {
                title: '',
                content: <ContactCheckboxNext input={inputCheckboxNext ? inputCheckboxNext : {}} oIDprocess={oIDprocess} onChange={setInputCheckboxNext} isComptePrevObltodisabled={isComptePrevObltodisabled} />,
            },
            {
                title: '',
                content: <OpenAccount messageErrorQuestionnaire={messageErrorQuestionnaire} setMessageErrorQuestionnaire={setMessageErrorQuestionnaire} setQuestionnaireFalse={setQuestionnaireFalse} typeCompte={inputCheckboxNext ? inputCheckboxNext : {}} setTypeCompte={setInputCheckboxNext} questionnaireReponse={questionnaireReponse} setQuestionnaireReponse={setQuestionnaireReponse} currentParentIndex={current} setCurrentParentIndex={setCurrent} setIsComptePrevObltodisabled={setIsComptePrevObltodisabled} setOIDprocess={setOIDprocess}/>,
            },
            {
                title: '',
                content: <QuestionEconomique questionnaireEconomiqueReponse={questionnaireEconomiqueReponse} setQuestionnaireEconomiqueReponse={setQuestionnaireEconomiqueReponse} setCurrentParentIndex={setCurrent}/>
            },
            {
                title: '',
                content: <ContactInputFields etatCivil={etatCivil} pays={pays} setPays={setPays} setEtatCivil={setEtatCivil} input={input.input ? input.input : {}} schema={schema} remarque={''} OIDprocess={OIDprocess} dateofbirth={{}} conditionAccepter={conditionAccepter} onChange={{setInput,setRemarque,setDateofbirth, setConditionAccepter}}    />,
            },
            // {
            //     title: '',
            //     content: <ChoixDateRDV rdv={rdv} setRdv={setRdv}/>,
            // },
            {
                title: '',
                content: <AllInfo rdv={{}}  />,
            }/*,
            {
                title: '',
                content: <ContactConfirmation />,
            },
            {
                title: '',
                content: 'Last-content',
            },*/
        ])
    },[])

    useEffect(() => {
        // if (questionnaireFalse) {
        //     setShowStepContent(false);
        //     setTimeout(() => {
        //         gototop();
        //     }, 200);
        // }
        setSteps([
            {
                title: '',
                content: <ContactCheckbox input={inputCheckbox ? inputCheckbox : {}} onChange={setInputCheckbox} next={next} setNext={setNext}/>,
            },
            {
                title: '',
                content: <ContactCheckboxNext input={inputCheckboxNext ? inputCheckboxNext : {}} oIDprocess={oIDprocess} onChange={setInputCheckboxNext} isComptePrevObltodisabled={isComptePrevObltodisabled} />,
            },
            {
                title: '',
                content: <OpenAccount messageErrorQuestionnaire={messageErrorQuestionnaire} setMessageErrorQuestionnaire={setMessageErrorQuestionnaire} setQuestionnaireFalse={setQuestionnaireFalse} typeCompte={inputCheckboxNext ? inputCheckboxNext : {}} setTypeCompte={setInputCheckboxNext} questionnaireReponse={questionnaireReponse} setQuestionnaireReponse={setQuestionnaireReponse} currentParentIndex={current} setCurrentParentIndex={setCurrent} setIsComptePrevObltodisabled={setIsComptePrevObltodisabled} setOIDprocess={setOIDprocess}/>,
            },
            {
                title: '',
                content: <QuestionEconomique questionnaireEconomiqueReponse={questionnaireEconomiqueReponse} setQuestionnaireEconomiqueReponse={setQuestionnaireEconomiqueReponse} setCurrentParentIndex={setCurrent}/>
            },
            {
                title: '',
                content: <ContactInputFields etatCivil={etatCivil} pays={pays} setPays={setPays} setEtatCivil={setEtatCivil} input={input.input ? input.input : {}} schema={schema} remarque={remarque} conditionAccepter={conditionAccepter} OIDprocess={OIDprocess} onChange={{setInput,setRemarque,setDateofbirth, setConditionAccepter}}/>,
            },
            // {
            //     title: '',
            //     content: <ChoixDateRDV rdv={rdv} setRdv={setRdv}/>,
            // },
            {
                title: '',
                content: <AllInfo etatCivil={etatCivil} pays={pays} setRecaptCha={setRecaptCha} input={input.input ? input.input : {}} typeCompte={inputCheckboxNext && inputCheckboxNext.input ? inputCheckboxNext.input : ''}  rdv={rdv} OIDprocess={OIDprocess} remarque={remarque} dateofbirth={dateofbirth} />,
            }/*,
            {
                title: '',
                content: <ContactConfirmation />,
            },
            {
                title: '',
                content: 'Last-content',
            },*/
        ])
    }, [ input.input, inputCheckbox, questionnaireEconomiqueReponse, pays, etatCivil, questionnaireFalse, inputCheckboxNext, rdv,remarque,dateofbirth,next, OIDprocess, isComptePrevObltodisabled, questionnaireReponse ])

    return (
        <>
        {
            lienOID && 
            <Redirect
                from='/Contact'
                to={{
                    pathname: "/start-oid",
                    state: { url: lienOID }
                }}
            />
        }
        {
            !lienOID && 
            (
                !showStepContent ? 
                (
                    loadingSendEmail ? 
                    (
                        <> 
                            <br /> <h1> Votre requête est en cours d'envoi !<br /><br />Merci de patienter...</h1> 
                        </> 
                    )
                    :
                    (
                        mailError ? <> 
                            <br /> <h1 className='red-line'> Un problème est survenu.<br /><br />Veuillez nous contacter par téléphone ou réessayer plus tard. <span className='black-line'>Merci de votre interêt pour la CEA.</span></h1> 
                        </> 
                        :
                        (
                            OIDprocess == 'NON' ? 
                            <> 
                                {/* <br /> <h1> Votre requête a bien été prise en compte !<br /><br />Nous vous contacterons dans un délai de 48h. Merci de votre intérêt pour la CEA.</h1>  */}
                                <br /> <h1> Votre requête a bien été prise en compte !<br /><br />Nous vous contacterons dans un délai de 48h. Merci de votre intérêt pour la CEA.</h1> 
                            </> 
                            :
                            <> 
                                {/* <br /> <h1> Votre requête a bien été prise en compte !<br /><br />Nous vous contacterons dans un délai de 48h. Merci de votre intérêt pour la CEA.</h1>  */}
                                <br /> <h1> Votre requête a bien été prise en compte !<br /><br />Vous allez être rédiriger dans 5 secondes pour continuer votre souscription. Cliquez sur ce <a className='redirect-oid' href={lienOID}>lien</a> si vous n'êtes pas redirigé.</h1> 
                            </> 
                        )
                    )
                )
                :
                <>
                    <h3>Suivez les étapes</h3>
                    <Steps current={current}>
                        {steps.map(item => (
                            <Steps.Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    {

    showStepContent && steps && steps[current] && steps[current].content ? <div className="steps-content">{steps[current].content}</div> : <div className="steps-content">{<ContactConfirmation />}</div>
                    }
                    <div className="steps-action">
                        {showStepContent && current > 0 /*&& current !== steps.length - 1*/ && (
                            <div class="stepperBtn">
                                <Button style={{ margin: '0 8px' }} onClick={() => {
                                    if (current - 1 == 1) {
                                        setOIDprocess('NON');
                                    }
                                    if(current === 4) {
                                        if(inputCheckbox.input.compte === "ouvrir un compte supplémentaire" || inputCheckbox.input.compte === "Ouvrir un compte (nouveau client)" ){
                                            if (inputCheckboxNext?.input?.comptePrevoyance || inputCheckboxNext?.input?.obligationCaisse) {
                                                setCurrent(2);
                                            }
                                            else {
                                                setCurrent(current - 1)
                                            }
                                        }else{
                                            setCurrent(0);
                                        }
                                    }else {
                                        setCurrent(current - 1);
                                    }
                                    }}>
                                    Précédent
                            </Button>
                            </div>
                        )}
                        {showStepContent && current < steps.length - 1 &&  (
                            <div className="stepperBtn">
                                <Button disabled={isToDisable()} style={{background: isToDisable() ? 'lightgray' : "#1b327e"}}   type="primary" onClick={() => {
                                    if (current === 0 && (!inputCheckbox.input.compte )) {
                                        message.error('Veuillez remplir cette partie!');
                                        console.log(next);
                                    }
                                    else {
                                        if (current === 1 && !(inputCheckboxNext.input.compteCourant || inputCheckboxNext.input.compteEpargne || inputCheckboxNext.input.comptePrevoyance || inputCheckboxNext.input.obligationCaisse)) {
                                            message.error('Veuillez remplir cette partie!')
                                        }
                                        else {
                                            let phoneRegest = /^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;
                                            if (current === 4 && input.error && remarque && remarque !== '') {
                                                message.error('Veuillez remplir tous les champs!');
                                            }
                                            else if (current === 4 && (!handleValidate(input.input?.phoneNumber) || input.input?.phoneNumber.charAt(0) == '0')) {
                                                message.error("Le numéro de téléphone doit commencer par + suivi de l'indicatif  et doit être valide");
                                            }
                                            else {  
                                            // console.log(inputCheckbox)
                                                if(current === 0) {
                                                    if(inputCheckbox.input.compte === "ouvrir un compte supplémentaire" || inputCheckbox.input.compte === "Ouvrir un compte (nouveau client)" ){
                                                        setCurrent(current + 1)

                                                    }else{
                                                        setCurrent(current + 4)
                                                    }
                                                }else {
                                                    setCurrent(current + 1)
                                                }
                                            }
                                        }
                                    }
                                }}>
                                    Suivant
                            </Button>
                            </div>

                        )}

                        {   reCaptcha && showStepContent && current === steps.length - 1 && (
                            <Button  /*disabled={!reCaptcha} */ type="primary" onClick={() => {
                                const { input: data } = input;
                                const { input: dataCheck } = inputCheckbox;
                                const { input: dataCheckNext } = inputCheckboxNext;
                                const questionnaireEconomique = JSON.stringify(questionnaireEconomiqueReponse)

                                setShowStepContent(false);
                                setLoadingSendEmail(true);
                                gototop();
                                var paysCode = COUNTRIES.find(c => c.name == pays)?.code;
                                console.log(paysCode)
                                var myDateofbirth = dateofbirth && dateofbirth.date ? new Date(dateofbirth.date) : '-- : --';
                                var myDate = rdv && rdv.date ? new Date(rdv.date) : '-- : --';
                                myDate = myDate !== '-- : --' ? (myDate.getDate() + '.' + (myDate.getMonth() + 1) + '.' + myDate.getFullYear()) : '-- : --';
                                myDateofbirth = myDateofbirth !== '-- : --' ? (myDateofbirth.getDate() + '.' + (myDateofbirth.getMonth() + 1) + '.' + myDateofbirth.getFullYear()) : '-- : --';
                                sendMail({ variables: { firstname: data.firstname, name: data.name, email: data.mail, phoneNumber: data.phoneNumber ? data.phoneNumber.toString().replace(/\s/g, '') : '--', dateofbirth: myDateofbirth, street: data.home ? data.home.street : '--', street_number: data.home ? data.home.street_number : '--', zip: data.home && data.home.zip ? data.home.zip.toString() : '--', country: data.home ? data.home.country : '--', compte: dataCheck.compte, /*credit: dataCheck.credit, divers: dataCheck.divers,*/ compteCourant: dataCheckNext && dataCheckNext.compteCourant? dataCheckNext.compteCourant : '' , compteEpargne: dataCheckNext.compteEpargne ? dataCheckNext.compteEpargne : '', comptePrevoyance: dataCheckNext.comptePrevoyance ? dataCheckNext.comptePrevoyance : '', obligationCaisse: dataCheckNext.obligationCaisse ? dataCheckNext.obligationCaisse : '', remarque: remarque, date: myDate, heure: rdv && rdv.heure ? rdv.heure : '--:--', OIDprocess: OIDprocess, pays: pays ? pays : '', paysCode: paysCode ? paysCode : '', etatCivil: etatCivil ? etatCivil : '', questionnaireEconomiqueReponse: questionnaireEconomique } });
                            }}>
                                Envoyer
                            </Button>
                        )}

                        {!showStepContent && (
                            <Button type="primary" onClick={() => {
                                setCurrent(0);
                                setShowStepContent(true);
                            }}>

                            </Button>
                        )}

                        {/* {
                        current === 2 && input && input.input && input.input.dateofbirth ? (setInput(...input, {dateofbirth: new Date(input.input.dateofbirth)})) : null
                        } */}


                    </div>
                </>
            )
        }
        </>
    )

}

export default ProgressBar

import React, { useEffect, useState } from "react";
// import "@fieldify/antd/dist/index.css";
import { Radio, Col, Row, Checkbox, Steps, Button, message, Timeline, Tooltip } from "antd";
import { BorderBottomOutlined } from "@ant-design/icons";

function Questionnaire({messageErrorQuestionnaire, setOIDprocess, setQuestionnaireFalse, setMessageErrorQuestionnaire, typeCompte, setTypeCompte, questionnaireReponse, setQuestionnaireReponse, currentParentIndex, setCurrentParentIndex, setIsComptePrevObltodisabled}) {
  const [questions, setQuestions] = useState([
    {
      key: 'client',
      titre: 'Client',
      question: "Êtes-vous déjà client(e) de la CEA ?",
      non: "",
      oui: "Vous pouvez ouvrir des comptes supplémentaires via l'e-banking de la CEA ou en téléphonant au numéro 021 821 12 60."
    },
    {
      key: 'domiciliation',
      titre: 'Domiciliation',
      question: "Êtes-vous domicilié(e) en Suisse ?",
      oui: "",
      non: "L'ouverture de compte en ligne est disponible uniquement pour les personnes domiciliées en Suisse.",
    },
    {
      key: 'residence',
      titre: 'Résidence',
      question: "Êtes-vous résident(e) fiscale(e) suisse ?",
      oui: "",
      non: "L'ouverture de compte en ligne est disponible uniquement pour les personnes qui sont exclusivement imposables en Suisse.",
    },
    {
      key: 'nationnalite',
      titre: 'Nationnalité Suisse',
      question: "Êtes-vous de nationalité suisse ?",
      oui: "",
      non: "",
    },
    {
      key: 'americaine',
      titre: 'Nationnalité américaine',
      question: 'Êtes vous de nationalité américaine ?',
      oui: "Vous ne pouvez pas ouvrir de compte en Suisse. Veuillez contacter la banque.",
      non: ""
    }, 
    {
      key: 'permis',
      titre: 'Permis séjour',
      question: "Disposez-vous d'un permis de séjour de catégorie C ou B?",
      oui: "", 
      // oui: "L'ouverture de compte en ligne n'est pas possible si vous ne disposez pas d'un permis de séjour catégorie B ou C.",
      non: "Veuillez noter: pour chaque ouverture de compte par une personne qui n'est pas de nationnalité suisse, une copie électronique du permis de séjour B ou C est indispensable.",
    },
    {
      key: 'age',
      titre: 'Âge',
      question: "Êtes-vous âgé(e)s d'au moins 18 ans?",
      oui: "",
      non: "L'âge minimum pour l'ouverture d'un compte en ligne est de 18 ans. Contactez-nous au numéro 021 821 12 60 et nous vous renseignerons volontiers.",
    }
  ]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});

  useEffect(() => {
    console.log(typeCompte);
    console.log(currentParentIndex);
    setCurrentQuestion(questions[0]);
  }, [])

  const handleAnswer = (answer, messageInfo = null) => {
    var error = '';
    setMessageErrorQuestionnaire('')
    let reponses = {
      ...questionnaireReponse
    }
    reponses[currentQuestion.key] = answer;
    setQuestionnaireReponse({
      ...reponses
    })
    switch(currentQuestion.key) {
      case 'client' : 
        if ( typeCompte?.input?.comptePrevoyance && typeCompte?.input?.comptePrevoyance ==="Compte de libre passage 2e pilier" || typeCompte?.input?.obligationCaisse) {
          if (!answer) {
            setTypeCompte({
              ...typeCompte,
              input: {
                ...typeCompte?.input,
                comptePrevoyance: undefined,
                obligationCaisse: undefined
              }
            })
            setTimeout(() => {
              setCurrentParentIndex(1);
            }, 200)
            setIsComptePrevObltodisabled(true);
          }
          else {
            setCurrentParentIndex(4);
          }
        } 
        else {
          if (answer) {
            setQuestionnaireFalse(true);
            error = "Vous pouvez ouvrir des comptes supplémentaires via l'e-banking de la CEA, par <a class='mailto' href='mailto:info@ceanet.ch'>mail</a> ou en téléphonant au numéro <br/> 021 821 12 60.";
            // setMessageErrorQuestionnaire();
          }
          else {
            setOIDprocess('OUI');
          }
        }
      break;
      case 'domiciliation' : 
        if (!answer) {
          setQuestionnaireFalse(true);
          error = "L'ouverture de compte en ligne est disponible uniquement pour les personnes domiciliées en Suisse.";
          // setMessageErrorQuestionnaire();
        }
      break;
      case 'residence' : 
        if (!answer) {
          setQuestionnaireFalse(true);
          error = "L'ouverture de compte en ligne est disponible uniquement pour les personnes qui sont exclusivement imposables en Suisse.";
          // setMessageErrorQuestionnaire();
        }
      break;
      case 'permis' : 
        if (!answer) {
          setQuestionnaireFalse(true);
          error = "Veuillez noter: pour chaque ouverture de compte par une personne qui n'est pas de nationnalité suisse, une copie électronique du permis de séjour B ou C est indispensable.";
          // setMessageErrorQuestionnaire();
        }
        else {
          // setQuestionnaireFalse(true);
          // error = "L'ouverture de compte en ligne n'est pas possible si vous ne disposez pas d'un permis de séjour catégorie B ou C.";
          // setMessageErrorQuestionnaire()
        }
      break;
      case 'age' : 
        if (!answer) {
          setQuestionnaireFalse(true);
          error = "L'âge minimum pour l'ouverture d'un compte en ligne est de 18 ans. Contactez-nous au numéro 021 821 12 60 <br/> et nous vous renseignerons volontiers.";
          // setMessageErrorQuestionnaire()
        }
      break;
      case 'americaine' : 
        if (answer) {
          setQuestionnaireFalse(true);
          error = "Vous ne pouvez pas ouvrir de compte en Suisse. Veuillez contacter la banque.";
          // setMessageErrorQuestionnaire()
        }
      break;
    }

    if (error) {
      // message.error(messageInfo, 1);
      setMessageErrorQuestionnaire(error);
    }
    else {
      if (currentQuestion.key == 'nationnalite' && answer) {
        setCurrentQuestion(questions[currentQuestionIndex + 3]);
        setCurrentQuestionIndex(currentQuestionIndex + 3);
      }else {
        if (currentQuestion.key == 'client' && (typeCompte?.input?.comptePrevoyance && typeCompte?.input?.comptePrevoyance ==="Compte de libre passage 2e pilier" || typeCompte?.input?.obligationCaisse)) {
          setCurrentQuestion(questions[currentQuestionIndex]);
        }
        else if (currentQuestionIndex < questions.length -1) {
          setCurrentQuestion(questions[currentQuestionIndex + 1]);
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else {
          setCurrentParentIndex(currentParentIndex + 1);
        }
      }
    }
  };

  return (
    <div className="questionnaire">
        <div className="questionnaire-step">
          <Steps 
            current={currentQuestionIndex}
            progressDot
            direction="vertical"
          >
              {questions.map(item => (
                  <Steps.Step key={item.titre} title={item.titre} />
              ))}
          </Steps>
        </div>
        <div className="steps-content questionnaire-content">
          <h2>{currentQuestion.question}</h2>
          <div>
            {/* {
              currentQuestion.oui && currentQuestion.key == 'client' && !(typeCompte?.input?.comptePrevoyance || typeCompte?.input?.obligationCaisse)?
              <Tooltip placement="bottom" title={currentQuestion.oui}>
                <Button onClick={() => handleAnswer(true, currentQuestion.oui)}> OUI </Button>
              </Tooltip>
              :
              (
                currentQuestion.oui && currentQuestion.key != 'client' ?
                <Tooltip placement="bottom" title={currentQuestion.oui}>
                  <Button onClick={() => handleAnswer(true, currentQuestion.oui)}> OUI </Button>
                </Tooltip>
                :
                <Button onClick={() => handleAnswer(true)}> OUI </Button>
              )
            }
            {
              currentQuestion.non ?
              <Tooltip placement="bottom" title={currentQuestion.non}>
                <Button onClick={() => handleAnswer(false, currentQuestion.non)}> NON </Button>
              </Tooltip>
              :
              <Button onClick={() => handleAnswer(false)}> NON </Button>
            } */}
            <Button onClick={() => handleAnswer(true)}> OUI </Button>
            <Button onClick={() => handleAnswer(false)}> NON </Button>
          </div>
          {
            messageErrorQuestionnaire &&
            <>
              <h1 className='red-line color-red' dangerouslySetInnerHTML={{__html: messageErrorQuestionnaire}}></h1>
            </>
          } 
        </div>

    </div>
  );
}

export default Questionnaire;
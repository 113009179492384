import React, { Fragment, useState } from 'react'
import AsideContentService from '../../Aside/AsideContentService'
import CeaFlecheLink from '../../../assets/images/CeaFlecheLink.png'
import Projets from '../../../assets/images/header_projets.jpg'
import { useQuery, gql } from '@apollo/client';

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "PROJET"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const PROJETS = gql`
    query {
        allCategorieProjets {
        id
        Nom
        Description
            Projets {
                id
                Titre
                Sections {
                    id
                    Titre
                    Contenu
                }
            }
        }
    }
`;

function ProjetArticle() {
    const [currentCollapse, setCollapse] = useState([]);
    const { loading: loadingProjet, error: errorProjet, data: dataProjet } = useQuery(PROJETS);
    const { loading, error, data } = useQuery(ACCROCHES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if (loadingProjet) return <p>Loading...</p>;
    if (errorProjet) return <p>Error :(</p>;

        const addCollapse = (id) => {
            if(currentCollapse.includes(id)) {
                setCollapse(currentCollapse.filter((e)=>(e !== id)));
            } else {
                setCollapse([...currentCollapse, id ]);
            }
        }

    return (
        <Fragment>
            <div className="slide-home">
                <div className="content-slides">
                    <div className="images">
                        <img src={Projets} alt="Ceanet" />
                    </div>
                </div>
            </div>
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                    <div key={accroche.id} className="col-md-12 info-home">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="info-contact info-one-column">
                                        <h1>{accroche.Titre}</h1>
                                        <p>{accroche.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )): ''
            }
            <div id="main-wrapper" className="col-md-12">
                <div className="container">
                    <div className="side-left">
                        {
                            dataProjet && dataProjet.allCategorieProjets ? dataProjet.allCategorieProjets.map((categorie) => (
                                <div key={categorie.id} className="collapsed-row">
                                    <h2 className="espace-moins">{categorie.Nom}</h2>
                                    <p className="content-desc-cat">{categorie.Description}</p>
                                    {
                                        categorie && categorie.Projets ? categorie.Projets.map((projet) => (
                                            <div key={projet.id}>
                                                <h3 onClick={() => { addCollapse(projet.id) }} className={currentCollapse.includes(projet.id) ? "collapsible  active" : "collapsible"}>
                                                    <span className="aide_content_title">{projet.Titre}</span>
                                                </h3>

                                                    <div className="content-collapse" style={{ display: currentCollapse.includes(projet.id) ? 'block' : 'none' }}>

                                                    {
                                                        projet && projet.Sections ? projet.Sections.map((section) => (
                                                            <div key={section.id}>
                                                                <div className="conditions">
                                                                    <div className="titre">{section.Titre}</div>
                                                                    <div className="contenu-projets" dangerouslySetInnerHTML={{__html: section.Contenu}}></div>
                                                                </div>
                                                                <div className="divider"></div>
                                                            </div>
                                                        )) : ''
                                                    }
                                                    <a className="vos_projet_link link-pages-projets" href="/Contact"> Contact <img src={CeaFlecheLink} alt="" /> </a>
                                                    <a className="vos_projet_link link-pages-projets" href="/Credits"> Crédits <img src={CeaFlecheLink} alt="" /> </a>
                                                </div>

                                            </div>
                                        )) : ''
                                    }
                                </div>
                            )) : ''
                        }
                    </div>
                    <AsideContentService />
                </div>
            </div>
        </Fragment>
    )
}

export default ProjetArticle

import React, { Fragment, Component } from 'react'
import '@fieldify/antd/dist/index.css'
import { Radio, Row, Col } from 'antd';

class ContactCheckbox extends Component {

    state = {
        input: {
            compte: null,
            credit: null,
            divers: null
        }
    }

    changeCheckbox(key, value) {
        this.setState({
            input: {
                compte: key === 'compte' ? value : this.state.input.compte,
                credit: key === 'credit' ? value : this.state.input.credit,
                divers: key === 'divers' ? value : this.state.input.divers
            }
        });
        this.props.onChange({
            input: {
                compte: key === 'compte' ? value : this.state.input.compte,
                credit: key === 'credit' ? value : this.state.input.credit,
                divers: key === 'divers' ? value : this.state.input.divers
            }
        });
    }

    componentDidMount() {
        this.setState(this.props.input) 
        console.log(this.props)
    }

    render() {

        return (
            <Fragment>
                <div class="right-form-checkbox">
                    <Row>
                        <Col xs={24} sm={24} lg={8} >
                            <div className="form_checkbox">
                                <div className="form-checkbox-title">
                                    <h2>Comptes</h2>
                                </div>
                                <div className="couple-label-input">
                                    <Radio.Group value={this.state.input.compte} onChange={(e) => this.changeCheckbox('compte', e.target.value)} >
                                        <Radio className="custom-checkbox" value='Ouvrir un compte (nouveau client)' onClick={ this.props.setNext(false)} >
                                        Ouvrir un compte (nouveau client)
                                </Radio>
                                        <div className="radioblock-divider"></div>
                                        <Radio className="custom-checkbox" value="ouvrir un compte supplémentaire" >
                                            Ouvrir un compte supplémentaire
                                </Radio>
                                        <div className="radioblock-divider"></div>
                                        <Radio className="custom-checkbox" value='ouvrir un compte pour un membre de la famille'>
                                            Ouvrir un compte pour un membre <br /> <span style={{ paddingLeft: '24px' }}>de la famille</span>
                                        </Radio>
                                     
                        
                                    </Radio.Group>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} lg={8} >
                            <div className="form_checkbox">
                                <div className="form-checkbox-title">
                                    <h2>Crédits</h2>
                                </div>
                                <div className="couple-label-input">
                                    <Radio.Group value={this.state.input.compte} onChange={(e) => this.changeCheckbox('compte', e.target.value)} >
                                        <Radio className="custom-checkbox" value='Demande de crédit'>
                                            Demande de crédit
                                </Radio>
                                        <div className="radioblock-divider"></div>
                                        <Radio className="custom-checkbox" value="Conseil pour un crédit ">
                                        Conseil pour un crédit
                                </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} lg={8} >
                            <div className="form_checkbox">
                                <div className="form-checkbox-title">
                                    <h2>Divers</h2>
                                </div>
                                <div className="couple-label-input">
                                    <Radio.Group value={this.state.input.compte} onChange={(e) => this.changeCheckbox('compte', e.target.value)} >
                                        <Radio className="custom-checkbox" value='Obtenir des informations générales'>
                                            Obtenir des informations générales
                                </Radio>
                                        {/* <div className="radioblock-divider"></div>
                                        <Radio className="custom-checkbox" value='Débuter par un rendez-vous'>
                                            Débuter par un rendez-vous
                                </Radio> */}
                                    </Radio.Group>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Fragment>
        )
    }

}

export default ContactCheckbox
import React, { Component, Fragment,useState } from 'react'
import ConditionGenerale from '../../../assets/pdf/conditions_generales_mars_2019.pdf'
import ConditionSite from '../../../assets/pdf/conditions_site_20201020.pdf'
import ConditionNet from '../../../assets/pdf/conditions-net-banking.pdf'
import {withRouter} from 'react-router-dom'

const Rendu = () => {
const [currentCollapse, setCurrentCollapse] = useState([])

    return(
 <Fragment>
                 <div className="col-md-12 info-home page-conditions">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="info-contact">
                                            <h1>Mentions légales</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <div id="main-wrapper" className="col-md-12 wrapper-conditions">
                    <div className="container">
                        <div className="side-left">
                          <div className="collapsed-row">
                                 <h3  id={"depot"} onClick={()=> setCurrentCollapse(currentCollapse.includes("depot") ? currentCollapse.filter(e => e !== "depot") : [...currentCollapse, "depot"] ) }  className={currentCollapse.includes("depot")  ? "collapsible active": "collapsible"}>
                                  <span className="aide_content_title">Mes dépôts sont-ils protégés par la garantie des dépôts esisuisse?</span>
                              </h3>
                              <div className={currentCollapse.includes("depot")  ? "content-collapse-projet show-bloc": "content-collapse-projet"} >
                                  <div className="conditions">
                                      <p>Oui, la Caisse d’Epargne d’Aubonne a l'obligation, comme toute banque et toute maison de titres en Suisse, de signer l’Autorégulation «Convention entre esisuisse et ses membres». Les dépôts des clients sont donc assurés jusqu'à un plafond de CHF 100’000 par client. Sont également considérées comme dépôts les obligations de caisse déposées auprès de la banque émettrice au nom du déposant.
<br /><br />
C'est esisuisse, dont le fonctionnement est expliqué en détail à l'adresse  <a href="https://www.esisuisse.ch/fr" target="_blank">https://www.esisuisse.ch/fr</a>, qui assure la garantie des dépôts en Suisse.
</p>
                                  </div>
                              </div>
                              <h3 id={"condition"} onClick={()=> setCurrentCollapse(currentCollapse.includes("condition") ? currentCollapse.filter(e => e !== "condition") : [...currentCollapse, "condition"] ) }  className={currentCollapse.includes("condition")  ? "collapsible active": "collapsible"}>
                                  <span className="aide_content_title">Conditions générales</span>
                              </h3>
                              <div className={currentCollapse.includes("condition")  ? "content-collapse-projet show-bloc": "content-collapse-projet"}>
                                  <div className="conditions align-center">
                                      <p>Télécharger le fichier pdf de nos conditions générales ici<br />
                                    <a href={ConditionGenerale}  rel="noopener noreferrer" target="_blank" className="link-pdf">Conditions générales</a>
                                  </p>
                                  </div>
                              </div>
                              <h3 id={"site"}  onClick={()=> setCurrentCollapse(currentCollapse.includes("site") ? currentCollapse.filter(e => e !== "site") : [...currentCollapse, "site"] ) }  className={currentCollapse.includes("site")  ? "collapsible active": "collapsible"}>
                                  <span className="aide_content_title">Conditions d’utilisation du site</span>
                              </h3>
                              <div   className={currentCollapse.includes("site") ? "content-collapse-projet show-bloc": "content-collapse-projet"}>
                                  <div className="conditions align-center">
                                    <p>Télécharger le fichier pdf des conditions d'utilisation du site web ici<br />
                                  <a href={ConditionSite}  rel="noopener noreferrer" target="_blank" className="link-pdf">Conditions d’utilisation du site</a></p>
                                  </div>
                              </div>
                              <h3 id={"net-banking"}  onClick={()=> setCurrentCollapse(currentCollapse.includes("net-banking") ? currentCollapse.filter(e => e !== "net-banking") : [...currentCollapse, "net-banking"] ) }  className={currentCollapse.includes("net-banking")  ? "collapsible active": "collapsible"}>
                                  <span className="aide_content_title">Conditions d’utilisation du NET-Banking</span>
                              </h3>
                              <div  className={currentCollapse.includes("net-banking") ? "content-collapse-projet show-bloc": "content-collapse-projet"}>
                                  <div className="conditions align-center">
                                    <p>Télécharger le fichier pdf des conditions d'utilisation du NET-Banking<br />
                                  <a href={ConditionNet}  rel="noopener noreferrer" target="_blank" className="link-pdf">Conditions d’utilisation du NET-Banking</a></p>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}
class ConditionContent extends Component {

    render(){

        return (
           <Rendu/>
        );
    }

    }

export default withRouter(ConditionContent)

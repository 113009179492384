import React from 'react';
import { gql, useQuery } from '@apollo/client';
import 'antd/dist/antd.css';
import { AiOutlineInfoCircle, AiOutlineClose } from 'react-icons/ai';
import {  Link } from 'react-router-dom';




const InfoRapide = ({ closeInfoHeader }) => {
    const description = gql
        `
    query {
        allArticles (
            where:{
                Info:true
              }
            first: 1
            sortBy: datePublication_DESC
        ) {
        id
        Titre
        Contenu
        Important
        Image {
            filename
        }
        datePublication
        Theme {
            Nom
        }
        }
    }
    `;

    const { data } = useQuery(description);


    return (
        <div className="info-rapide">


            {
                 data && data.allArticles.map((article,index) => {
                   let date = new Date(article.datePublication)
                   const datePublication = `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;

                   console.log(date.getFullYear())
                   return(
                    <>

                      <div className="info-rapide-content" key={article.id}>
                        <div className="date-publication">{datePublication}</div>
                               <div className="content" dangerouslySetInnerHTML={{ __html: index === 0 ? (article.Contenu && article.Contenu.length > 400 ? article.Contenu.substr(0, 400) + ' ...' : article.Contenu) : '' }} />

                      </div>
                      <Link className="plus-info" to={{ pathname: `/Actualites/${article.id}` }}> En apprendre plus</Link>
                      <AiOutlineClose className="icon-ferme" onClick={closeInfoHeader}/>
                      <div >
                          <AiOutlineInfoCircle className="icon-info"/>
                      </div>
                </>
            )
            })

            }

        </div>
    );
};

export default InfoRapide;

import React from 'react'
import LogoCea from '../../assets/images/LogoCea.svg'
import { Link } from 'react-router-dom'

function Logo() {
    return (
        <div className="logo">
            <Link to={"/"}>
                <img src={LogoCea} alt="" />
            </Link>
        </div>
    )
}

export default Logo

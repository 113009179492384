import React from 'react'
import Ceadownload from '../../../assets/images/Ceadownload.svg'
import { useQuery, gql } from '@apollo/client';
const lastBrochures = gql`
    query {
        allBrochures (
        first: 2
        sortBy: id_DESC
        where: {
            TypeDemandeCompte: true
        }
        ){
        id
        Titre
        Fichier {
            filename
        }
        }
    }
`;



const ContactConfirmation = () => {
    const { loading, error, data } = useQuery(lastBrochures);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    return (
        <>
            <div className="contact-conf">
                <div class="divider_2"></div>
                <h2>Ouverture de compte - Confirmation</h2>
                <p style={{ paddingBottom: '20px' }}>  Merci ! Votre demande a bien été envoyée ! Vous allez recevoir sous peu un e-mail de confirmation. </p>
                <p style={{ paddingBottom: '20px' }}>
                    Nous vous recontacterons le plus rapidement possible. En attendant et afin d’accélérer le processus, nous vous demandons de préparer les documents suivants (1 copie et l’original)
                 <ul style={{ paddingTop: '20px' }}>
                        <li>- carte d’identité ou passeport</li>
                        <li>- DOC 1</li>
                        <li>- DOC 2</li>
                        <li>- DOC 3</li>
                    </ul></p>
                <p style={{ paddingBottom: '20px' }} >vous pouvez consulter les brochures ci-dessous pour savoir plus en détail les autres types de compte ou comment se passe la relation avec la CEA. Merci de nous faire confiance !</p>
                {
                    data && data.allBrochures ? data.allBrochures.map((brochure) => (
                        <a key={brochure.id} className="contact-infbrochure" href={'uploads/' + brochure.Fichier.filename} target="_blank" download> {brochure.Titre} <img style={{ float: "right", paddingRight: '20px' }} src={Ceadownload} alt="img" /></a>
                    )) : ''
                }

            </div>
        </>
    )
}
export default ContactConfirmation 

import React, { Fragment } from 'react'
import Contact from '../../../assets/images/header_contact.jpg'
import { useQuery, gql } from '@apollo/client';

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "CONTACT"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;


function ContactHeaderSlider() {
    const { loading, error, data } = useQuery(ACCROCHES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    return (
        <Fragment>
            <div className="slide-home">
                <div className="content-slides">
                    <div className="images">
                        <img src={Contact} alt="Ceanet" />
                    </div>

                </div>
            </div>
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                    <div key={accroche.id} className="col-md-12 info-home">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="info-contact info-one-column">
                                        <h1>{accroche.Titre}</h1>
                                        <p>{accroche.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )): ''
            }
        </Fragment>
    )
}

export default ContactHeaderSlider

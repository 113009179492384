import React, { useState , useEffect} from 'react'
import { useQuery, gql } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';

function Section() {

    const history = useHistory();
    const [offres, setOffres] = useState([]);
    const [pathname, setPathname] = useState('/');

    useEffect(() => {
      switch (window.location.pathname) {
        case '/': setPathname('ACCUEIL')
          break;
        case '/LaBanque': setPathname('LaBanque')
          break;
        case '/Comptes': setPathname('Compte')
          break;
        case '/Credits': setPathname('Credit')
          break;
        case '/Services': setPathname('Service')
          break;
        case '/VosProjets': setPathname('Projet')
          break;
        case '/AideFaq': setPathname('AIDEFAQ')
          break;
        default:

      }
    }, [pathname])

    const offreLast4 = gql`
        query {
            allOffres (
                first: 3
                sortBy: id_DESC
                where:{
                   Page_some:{
                     Nom_i: "${pathname}"
                   }
                }
            ){
                id
                Titre
                ContenuBouton
                Link
                Image {
                    filename
                }
                Contenu
            }
        }
    `;


    useQuery(offreLast4, {
        onCompleted: (data) => {
            if (data && data.allOffres && data.allOffres.length > 0) {
                setOffres(data.allOffres);
            }
        }
    });


    return (
        <div className="section-offre">
            <div className="container">
                <div className="row">
                    <h2>Offres à découvrir</h2>
                    <div className="slide-mobile">
                    {
                        offres ? offres.map((offre) => (
                            <article key={offre.id}>
                                <img src={'uploads/' + (offre.Image && offre.Image.filename ? offre.Image.filename : '')} alt={offre.Image && offre.Image.filename ? offre.Image.filename : ''} />
                                <div className="content">
                                    <h3>{offre.Titre}</h3>
                                    <div className="description-height" dangerouslySetInnerHTML={{ __html: offre.Contenu }}  />
                                    <Link className="link-decouvrir" to={offre.Link}>{offre.ContenuBouton}</Link>
                                </div>
                            </article>
                        )) : ''
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section

import React, { Fragment, Component } from 'react'
import MapContainerDiv from '../Contact/MapContainerDiv'
import BottomImageDiv from '../Contact/BottomImageDiv'
import ProgressBar from '../Contact/ProgressBar'


class ContactMainContent extends Component {

    render() {

        return (
            <Fragment>
                <div className="middle_content">
                    <div className="container">
                        <div className="form_content">
                            <h2>Formulaire de contact</h2>
                            <ProgressBar />
                        </div>
                    </div>

                    <MapContainerDiv />
                    <BottomImageDiv />
                </div>
            </Fragment>
        )

    }


}

export default ContactMainContent

import React from 'react'

function Copyright() {
    return (
        <div className="copyright col-md-12">
            <div className="container">
                <p> «  © 2022 Caisse d’Epargne d’Aubonne, tous droits réservés »  </p>
            </div>
        </div>
    )
}

export default Copyright

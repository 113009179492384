import React, { Component } from 'react';
import Logo from './Logo';
import HeaderNav from './HeaderNav';

class Header extends Component {
    render() {
        return (
            <header className="col-md-12">
                <Logo />
                <HeaderNav />
            </header>
        )
    }
}

export default Header

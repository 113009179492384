import React, { Fragment, Component } from 'react'
import SimpleMap from '../Contact/SimpleMap'


export  class  MapContainerDiv extends Component {
    componentDidMount() {
        setTimeout(() => {
            if(window.location.hash){
                console.log(window.location.hash.split('#')[1])
                var ele = document.getElementById(window.location.hash.split('#')[1]);
                window.scrollTo(ele.offsetLeft,ele.offsetTop - 100);
            }

        }, 500)
    }
render() {
        return (

            <Fragment>
                <div className="map_div" id="mapdivcontact">
                    <div className="side_left-div ">
                        <div className="container">
                            <div className="col-md-6 first_half">
                                <div className="col-md-12">
                                    <h2>Où nous trouver ?</h2>
                                </div>
                                <div className="col-md-6">
                                    <p><span>Caisse d’Epargne d’Aubonne société coopérative</span> <br />
                            Rue de l’Hôtel-de-Ville 21 <br />
                            Case postale 56 <br/>
                            1170 Aubonne <br /><br /></p>
                                </div>

                                <div className="col-md-6 adresse-mobile">
                                    <div className="img_tel"> <img src="./images/cea-phone.svg" alt="" /></div>
                                    <div className="prgh_">
                                        <p>
                                          <span>Horaire</span> 7h30 – 12h00 / 13h30 – 17h00 <br />
                                          <span>Téléphone</span> 0041 21 821 12 60 <br />
                                            <span> Fax </span>021 808 79 42
                            </p>
                                    </div>

                                    <div className="adress_icon">
                                        <div className="img_at"> <img src="./images/cea-mail.svg" alt="" /></div>
                                        <div className="icon_at_text">
                                            <p> <span>E-mail</span> <a href="mailto:Info@ceanet.ch">info@ceanet.ch</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{position:"relative"}} className="side_right-div">
                        <SimpleMap/>
                    </div>
                </div>
            </Fragment>
        )
}
}
export default MapContainerDiv

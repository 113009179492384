import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const mystyle = {
    width: '304px',
     margin: '66px auto'
}

 class Captcha extends Component {

state = {
    isVerified:false
}
verifyCaptcha = (response) => {
    alert('responsed');
    console.log(response);
if(response){
    this.setState({
        isVerified:true
    })
}
}
recaptchaLoaded = (response) => {
console.log('captcha loaded')
}
handleSubscription = () => {
    alert('responsed');
    if(this.state.isVerified){
        alert('check')
    }else{
        alert('human')
    }
}

   render(){
       return (
           <div style={mystyle} className="captcha">
               <ReCAPTCHA
                   sitekey="6LdmGAoaAAAAAGRsex-FdS2me_m3hSdMkcLP-qMA"
                   render="explicit"
                   onChange={(value) => {
                       this.props.setRecaptCha(true);
                   }}
               />

           </div>


       )
   }

}


export default Captcha

import React from 'react'
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "ACCUEIL"
            }
        ){
            id
            Nom
            Accroches (
                first: 2
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

function Card() {
    const { data } = useQuery(ACCROCHES);

    return (
        <div className="col-md-12 info-home">
            <div className="container">
                <div className="row">
                    {
                        data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                            <div className="col-md-6" key={accroche.id}>
                                <div className="info-contact">
                                    <h3>{accroche.Titre}</h3>
                                    <h2>{accroche.SousTitre}</h2>
                                    <p>{accroche.Description}</p>
                                    {
                                        accroche.Links ? accroche.Links.map((link) => (
                                        <div key={link.id}> <Link to={link.Lien}>{link.Nom}</Link> </div>
                                       
                                        )) : ''
                                    }
                                </div>
                            </div>
                        )) : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Card

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const style = {
    image: {
        height: '400px'
    },
    content: {
        height: '115px',
        marginTop: '1%',
        marginBottom: '1%'
    },
    contentMobile: {
        height: '115px',
        marginTop: '1%',
        marginBottom: '7%'
    },
    themeTypes: {
        marginTop: '-40px',
    }
}


const DetailsActualite = ({ location }) => {
   
    const { id } = useParams();
    let fromHome = false;
    if (location) {
        location.state ? fromHome = true : fromHome = false;
    };
    useEffect(() => {
        if (fromHome) {
            if (document.getElementById('burgerId')) {
                document.getElementById('burgerId').remove()
            }
            const node = document.createElement('script');
            node.src = '/js/responsive.js';
            node.type = 'text/javascript';
            node.id = 'burgerId'
            document.getElementById('body').appendChild(node);
        }
    }, []);
    const infoActualite = gql`
        query {
            Article (
                where: {
                  id: "${id}"
                }
            ) {
            id
            Titre
            Contenu
            Important
            Image {
                filename
            }
            datePublication
            Theme {
                Nom
            }
            }
        }
    `;
    const { loading, error, data } = useQuery(infoActualite);


        console.log("data",data);
    if (loading) return <p>loading.........</p>;
    else if (error) return <p>error coté serveur</p>
    else
        return (
            <div className="container">
                    <article>
                        <img style={{ width: "100%", marginTop: "2%" }} src={data.Article.Image ? '../uploads/' + data.Article.Image.filename : ''} alt={data.Article.Image ? data.Article.Image.filename : ''} />
                        <div className="content">
                            <div className="date_actualite" style={{ marginTop: "1%" }}>{
                                new Intl.DateTimeFormat("fr-GB", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                }).format(new Date(data.Article.datePublication))
                            } </div>
                            <h3 className="titre-detail-actualite" style={{ color: "#1b327e", fontSize: "25px" }}> {data.Article.Titre}</h3>
                            <div dangerouslySetInnerHTML={{ __html: data.Article.Contenu }} className="contenu-detail-actualite" />
                        </div>

                    </article>
            </div>
        );
};

export default DetailsActualite;
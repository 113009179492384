import React from 'react'
import { useQuery, gql } from '@apollo/client';
import RapportAnnuel from './RapportAnnuel';
import { Link } from 'react-router-dom';
const ACTUALITES = gql`
    query {
        allArticles (
            first: 3
            sortBy: datePublication_DESC
        ) {
        id
        Titre
        Contenu
        Important
        Image {
            filename
        }
        datePublication
        Theme {
            Nom
        }
        }
    }
`;

function AsideContentAdde() {

    const { loading, error, data } = useQuery(ACTUALITES);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className="side-right">
            <h2>Informations importantes</h2>
            {
                data && data.allArticles ? data.allArticles.map((article) => (
                    <div className="actualites">
                        <div className="right-aide">
                            <Link to={"/Actualites/"+article.id}>
                                <h3>{article.Titre}</h3>

                            <div dangerouslySetInnerHTML={{ __html: (article.Contenu && article.Contenu.length > 100 ? article.Contenu.substr(0, 100) + ' ...' : article.Contenu) }} />
                            </Link>
                        </div>
                    </div>

                )) : ''
            }


        </div>
    )
}

export default AsideContentAdde

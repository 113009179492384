import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import BanqueArticle from '../LaBanque/BanqueArticle'
import Section from '../../Article/Section'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import { Helmet } from 'react-helmet';

function Labanque() {

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <Fragment>
          <Helmet>
            <title>Caisse d’épargne d’Aubonne - La Banque</title>
            <meta name="description" content="Coopérative fondée en 1837, la Caisse d’Epargne d’Aubonne offre depuis son origine des services bancaires traditionnels de la banque de détail avec le désir de contribuer à la prospérité de sa région." />
          </Helmet>
            <Header />
            <SideBtnLink />
            <BanqueArticle />
            <Section />
            <Footer />

            <Copyright />
        </Fragment>
    )
}

export default Labanque

import React, { useEffect } from 'react';
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import { useParams } from 'react-router-dom';
import DetailsOffre from './DetailOffre';


const Offre = ({ location }) => {
    const { id } = useParams();
    let fromHome = false;
    if(location){
        location.state ? fromHome = true : fromHome = false;
    }

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <>
        <Header />
        <SideBtnLink />
        <DetailsOffre id={id} fromHome={fromHome}/>
        <Footer />
       
        <Copyright />
    </>
    );
};

export default Offre;
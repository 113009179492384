import React, { Fragment, Component } from "react";
import "@fieldify/antd/dist/index.css";
import { Radio, Col, Row, Checkbox } from "antd";

class ContactCheckboxNext extends Component {
  state = {
    input: {
      compteCourant: null,
      compteEpargne: null,
      comptePrevoyance: null,
      
      obligationCaisse: null,
    },
  };

  changeCheckbox(key, value) {
    this.setState({
      input: {
        compteCourant:
          key === "compteCourant" ? value : this.state.input.compteCourant,
        compteEpargne:
          key === "compteEpargne" ? value : this.state.input.compteEpargne,
        comptePrevoyance:
          key === "comptePrevoyance"
            ? value
            : this.state.input.comptePrevoyance,
        obligationCaisse:
          key === "obligationCaisse"
            ? value
            : this.state.input.obligationCaisse,
      },
    });
    this.props.onChange({
      input: {
        compteCourant:
          key === "compteCourant" ? value : this.state.input.compteCourant,
        compteEpargne:
          key === "compteEpargne" ? value : this.state.input.compteEpargne,
        comptePrevoyance:
          key === "comptePrevoyance"
            ? value
            : this.state.input.comptePrevoyance,
        obligationCaisse:
          key === "obligationCaisse"
            ? value
            : this.state.input.obligationCaisse,
      },
    });
  }
  componentDidMount() {
    this.setState(this.props.input);
  }

  render() {
    console.log("this.props.isComptePrevObltodisabled : ", this.props.isComptePrevObltodisabled)
    return (
      <Fragment>
        <div class="divider_2"></div>
        <h2>Choix du type de compte</h2>
        <p style={{ color: "#848484" }}>
          Cette page résume les différents comptes que propose la CEA. Faites
          votre choix !
        </p>
        <div class="divider_2"></div>

        <div class="next-form-checkbox">
          <Row>
            <Col xs={24} sm={12} lg={6}>
              <div className="next-checkbox">
                <div className="next-checkbox-title">
                  <h2>Comptes courants</h2>
                </div>
                <div className="couple-label-input-next">
                  <Radio.Group
                    value={this.state.input.compteCourant}
                    onChange={(e) =>
                      this.changeCheckbox("compteCourant", e.target.value)
                    }
                  >
                    <Radio className="custom-checkbox" value="Salaire">
                      Salaire
                    </Radio>

                    <Radio className="custom-checkbox" value="Jeunesse">
                      Jeunesse
                    </Radio>

                    <Radio className="custom-checkbox" value=" Courant">
                      Courant
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <div className="next-checkbox">
                <div className="next-checkbox-title">
                  <h2>Comptes épargne</h2>
                </div>
                <div className="couple-label-input-next">
                  <Radio.Group
                    value={this.state.input.compteEpargne}
                    onChange={(e) =>
                      this.changeCheckbox("compteEpargne", e.target.value)
                    }
                  >
                    <Radio className="custom-checkbox" value="Nominatif">
                      Nominatif
                    </Radio>

                    <Radio className="custom-checkbox" value="Placement">
                      Placement
                    </Radio>

                    <Radio className="custom-checkbox" value="Senior">
                      Sénior
                    </Radio>

                    <Radio className="custom-checkbox" value="Jeunesse">
                      Jeunesse
                    </Radio>

                    <Radio className="custom-checkbox" value="Plus">
                      Plus
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <div className="next-checkbox">
                <div className="next-checkbox-title">
                  <h2>Comptes prévoyance</h2>
                </div>
                <div className="couple-label-input-next">
                  <Radio.Group
                    disabled={this.props.isComptePrevObltodisabled}
                    value={this.state.input.comptePrevoyance}
                    onChange={(e) =>
                      this.changeCheckbox("comptePrevoyance", e.target.value)
                    }
                  >
                    <Radio 
                      className="custom-checkbox"
                      value="Compte de libre passage 2e pilier"
                    >
                      Compte de libre passage 2e pilier
                    </Radio>
                    <Radio 
                      className="custom-checkbox"
                      value="Compte 3e pilier 3a"
                    >
                      Compte 3e pilier 3a
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <div className="next-checkbox">
                <div className="next-checkbox-title">
                  <h2>Obligations de caisse</h2>
                </div>
                <div className="couple-label-input-next">
                  <Radio.Group
                    disabled={this.props.isComptePrevObltodisabled}
                    value={this.state.input.obligationCaisse}
                    onChange={(e) =>
                      this.changeCheckbox("obligationCaisse", e.target.value)
                    }
                  >
                    <Radio className="custom-checkbox" value="ODC 3 ans">
                      ODC 3 ans
                    </Radio>
                    <Radio className="custom-checkbox" value="ODC 4 ans">
                      ODC 4 ans
                    </Radio>
                    <Radio className="custom-checkbox" value="ODC 5 ans">
                      ODC 5 ans
                    </Radio>
                    <Radio className="custom-checkbox" value="ODC 6 ans">
                      ODC 6 ans
                    </Radio>
                    <Radio className="custom-checkbox" value="ODC 7 ans">
                      ODC 7 ans
                    </Radio>
                    <Radio className="custom-checkbox" value="ODC 8 ans">
                      ODC 8 ans
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}
export default ContactCheckboxNext;

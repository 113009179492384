import React, { Fragment, Component } from 'react'
import Header from '../../Header/Header'
import SideBtnLink from '../../Aside/SideBtnLink'
import CompteArticle from '../Comptes/CompteArticle'
import Section from '../../Article/Section'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import { Helmet } from 'react-helmet'



class Comptes extends Component {

    componentDidMount() {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }

    render() {
        return (
            <Fragment>
              <Helmet>
                <title>Caisse d’épargne d’Aubonne - Comptes</title>
                <meta name="description" content="Pour chaque situation, nous avons le compte bancaire qui s’adapte à votre besoin. Découvrez rapidement nos taux de rémunérations au-dessus de la moyenne et sans limite ainsi que nos services associés." />
              </Helmet>
                <Header />
                <SideBtnLink />
                <CompteArticle />
                <Section />
                <Footer />
                <Copyright />
            </Fragment>
        )
    }
}

export default Comptes

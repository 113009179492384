import React, { Fragment, useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client';
import { Tabs } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Copyright from '../../Footer/Copyright';
import Foot from '../../../assets/images/Foot.png'
import Actualites from '../../../assets/images/header_actualites.jpg'
import CeaSearch from '../../../assets/images/CeaSearch.svg';

function ActualiteArticle() {
    let history = useHistory();
    let location = useLocation();
    const handleDetail = id => {
        history.push(`${location.pathname}/${id}`);
    }

    const allActualitiesImportance = gql`
        query {
            allArticles (
                where: {
                    Important: true
                },
                first: 2
                sortBy: datePublication_DESC
            ) {
            id
            Titre
            Contenu
            Important
            Image {
                filename
            }
            datePublication
            Theme {
                Nom
            }
            }
        }
    `;

    const allActualitiesDate = gql`
        query {
            allArticles (
                first: 2
                sortBy: datePublication_DESC
            ) {
            id
            Titre
            Contenu
            Important
            Image {
                filename
            }
            datePublication
            Theme {
                Nom
            }
            }
        }
    `;

    const allTheme = gql`
        query {
            allThemes {
                id
                Nom
            }
        }
    `;

    const [currentFilter, setFilter] = useState('date');
    const [listNumber, setListNumber] = useState(8);
    const [allActualities, setActualities] = useState(allActualitiesDate);
    const [textFilter, setTextFilter] = useState('');
    const [currentTheme, setTheme] = useState({ id: '', Nom: '' });
    const allActualitiesTheme = gql`
        query {
            allArticles (
                where: {
                    Theme: {
                    id: "${currentTheme.id}"
                    }
                },
                first: 2
                sortBy: datePublication_DESC
            ) {
            id
            Titre
            Contenu
            Important
            Image {
                filename
            }
            datePublication
            Theme {
                Nom
            }
            }
        }
    `;


    const filtreActualitiesDate = gql`
        query {
            allArticles (
                where: {
                    OR: [
                            {
                                Titre_contains_i: "${textFilter}"
                            }
                            {
                                Contenu_contains_i: "${textFilter}"
                            }
                            {
                                datePublication_in: [
                                    "${textFilter}"
                                ]
                            }
                            {
                                Theme: {
                                    Nom_contains_i: "${textFilter}"
                                }
                            }
                    ]
                }
                first: 2
                sortBy: datePublication_DESC
            ) {
                id
                Titre
                Contenu
                Important
                Image {
                    filename
                }
                datePublication
                Theme {
                    Nom
                }
            }
        }
    `;



    const last8Actualities = gql`
        query {
            allArticles (
                first: ${listNumber}
                sortBy: datePublication_DESC
            ) {
            id
            Titre
            Contenu
            Important
            Image {
                filename
            }
            datePublication
            Theme {
                Nom
            }
            }
        }
    `;

    useEffect(() => {
        if (currentFilter === 'theme') {
            setActualities(allActualitiesTheme);
        }
    }, [allActualitiesTheme, currentFilter, currentTheme]);

    useEffect(() => {
        setActualities(filtreActualitiesDate);
    }, [textFilter]);

    const { loading, error, data } = useQuery(allActualities);
    const { loading: loadingActualite, error: errorActualite, data: dataActualite } = useQuery(last8Actualities);
    const { loading: loadingTheme, error: errorTheme, data: themeData } = useQuery(allTheme, {
        onCompleted: (data) => {
            if (themeData && themeData.allThemes && themeData.allThemes.length > 0) {
                setTheme(themeData.allThemes[0]);
            }
        }
    });

    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : (</p>;
    // if (loadingTheme) return <p>Loading...</p>;
    if (errorTheme) return <p>Error :(</p>;

    return (
        <Fragment>
            <div className="slide-home">
                <div className="content-slides">
                    <div className="images">
                        <img src={Actualites} alt="Ceanet" />
                    </div>
                </div>
            </div>
            <div className="col-md-12 info-home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="info-contact info-one-column">
                                <h1>Actualités</h1>
                                <p>Découvrez les dernières actualités de la Caisse d’Epargne d’Aubonne ainsi que tous nos conseils pour la gestion de votre argent.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-actualite">
                <div className="container">
                    <div className="row">
                        <div className="up-linksBtn">
                            <p>Vous ne trouvez pas une information ? Utilisez ces filtres!</p>
                            <Link to={"#"} className={currentFilter === 'theme' ? 'par-theme' : ''} onClick={() => { setFilter('theme'); setActualities(allActualitiesTheme) }} >Par thème</Link>
                            <Link to={"#"}className={currentFilter === 'date' ? 'par-theme' : ''} onClick={() => { setFilter('date'); setActualities(allActualitiesDate) }} >Par date</Link>
                            <Link to={"#"}   className={currentFilter === 'date' ? 'par-theme' : ''} onClick={() => { setFilter('date'); setActualities(allActualitiesDate) }} >Réinitialiser</Link>
                            <div className="inp-container"><input onChange={(e) => { setFilter('date'); setActualities(filtreActualitiesDate); setTextFilter(e.target.value) }} name="recherche" type="search" placeholder="Recherche" /> <img src={CeaSearch} alt="CeaSearch" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-actualite">
                <div className="container">
                    <div style={style.themeTypes}>
                        <Tabs defaultActiveKey={currentTheme.id} onChange={(e) => { setTheme(themeData.allThemes.find(td => td.id === e)) }} >
                            {
                                currentFilter === 'theme' && themeData && themeData.allThemes ? themeData.allThemes.map((theme) => (
                                    <Tabs.TabPane key={theme.id} tab={theme.Nom} ></Tabs.TabPane>
                                )) : ''
                            }
                        </Tabs>
                    </div>
                    <div className="row">
                        {
                            data && data.allArticles ? data.allArticles.map((article) => (
                                <article key={article.id} className="important description-height">
                                    <div className="header-important">
                                        <span> {currentFilter === 'importance' ? 'Important' : (currentFilter === 'date' ? 'Dernieres actualités' : currentTheme.Nom)} </span>
                                    </div>
                                    <a onClick={() => handleDetail(article.id)}><img style={style.image} src={'/uploads/' + article.Image.filename} alt={article.Image.filename} />
                                    </a>
                                    <div className="content">
                                        <div className="date_actualite">{
                                            new Intl.DateTimeFormat("fr-GB", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                            }).format(new Date(article.datePublication))
                                        } </div>
                                        <h3 onClick={() => handleDetail(article.id)}><a>{article.Titre}</a></h3>
                                        <div style={style.content} dangerouslySetInnerHTML={{ __html: (article.Contenu && article.Contenu.length > 400 ? article.Contenu.substr(0, 400) + ' ...' : article.Contenu) }} />
                                    </div>
                                </article>
                            )) : ''
                        }
                    </div>
                </div>
            </div>
            <div class="section-actualite">
                <div class="container">
                    <div class="row">
                        {
                            dataActualite && dataActualite.allArticles ? dataActualite.allArticles.map((actualite) => (
                                <article key={actualite.id} >
                                    <a onClick={() => handleDetail(actualite.id)} >
                                        <img src={actualite.Image ? '/uploads/' + actualite.Image.filename : ''} alt={actualite.Image ? actualite.Image.filename : ''} />
                                    </a>
                                    <div class="content">
                                        <div className="date_actualite">{
                                            new Intl.DateTimeFormat("fr-GB", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit"
                                            }).format(new Date(actualite.datePublication))
                                        } </div>
                                        <h3 onClick={() => handleDetail(actualite.id)} ><a>{actualite.Titre}</a></h3>
                                        <div style={style.content} dangerouslySetInnerHTML={{ __html: (actualite.Contenu && actualite.Contenu.length > 150 ? actualite.Contenu.substr(0, 150) + ' ...' : actualite.Contenu) }} />
                                    </div>

                                </article>

                            )) : ''
                        }
                    </div>

                </div>
            </div>
            <div class="section-actualite">
                <div class="container">
                    <div class="row">
                        <div onClick={() => setListNumber(listNumber + 4)} class="end-button">
                            <Link to={"#"}>Voir plus</Link>
                            <img src="./images/icon-fleche-bottom.svg" alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const style = {
    image: {
        height: '400px'
    },
    content: {
        height: '155px'
    },
    themeTypes: {
        marginTop: '-40px',
    }
}

export default ActualiteArticle;

import React, { Fragment, useState, useEffect } from 'react'
import Header from '../Header/Header'
import Slide from '../Article/Slide'
import Card from '../Article/Card'
import Article from '../Article/Article'
import Section from '../Article/Section'
import Footer from '../Footer/Footer'
import Copyright from '../Footer/Copyright'
import SideBtnLink from '../Aside/SideBtnLink'
import InfoRapide from '../../components/Header/InfoRapide';
import { Helmet } from 'react-helmet';

function Accueil() {
    const [showInfo, setshowInfo] = useState(true);
      const closeInfoHeader = ()=>{
          setshowInfo(false);
      }

      useEffect(() => {
        setTimeout(() => {
            if(!(window.location && window.location.hash)){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <Fragment>
            <Helmet>
              <title>Caisse d’épargne d’Aubonne - Accueil</title>
              <meta name="description" content="Bienvenue sur la page d’accueil de la Caisse d'Epargne d'Aubonne est une société coopérative implantée dans les districts de Morges et de Nyon, dans le canton de Vaud." />
            </Helmet>
            <Header />
            {showInfo && <InfoRapide closeInfoHeader={closeInfoHeader}/>}
            <Slide />
            <Card />
            <SideBtnLink />
            <Article />
            <Section />
            <Footer />  
            <Copyright />
        </Fragment>

    )
}

export default Accueil

import React, { Fragment, Component } from 'react'
import SideBtnLink from '../../Aside/SideBtnLink'
import { Link } from 'react-router-dom'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import ContactHeaderSlider from './ContactHeaderSlider'
import ContactMainContent from './ContactMainContent'
import Header from '../../Header/Header'
import { Helmet } from 'react-helmet'
import successImage from '../../../assets/images/success.jpeg';
import Lottie from 'react-lottie';
import identificationData from '../../../assets/lotties/identification.json'
import agenda from '../../../assets/lotties/agenda.json'

const identificationOptions = {
    loop: true,
    autoplay: true, 
    animationData: identificationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

const agendaOptions = {
    loop: true,
    autoplay: true, 
    animationData: agenda,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

class ChoixSubscription extends Component {

    state = {
        url: '',
        token: ''
    }

    componentDidMount() {
        setTimeout(() => {
            if (!window.location.hash) {
                window.scrollTo(0, 0);
            }
        }, 500)
        const url = this.props.location && this.props.location.state ? this.props.location.state.url : '';
        console.log(url);
        this.setState({
            url,
            token: url ? url.split('/')[url.split('/')?.length -1] : ''
        })
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Caisse d’épargne d’Aubonne - Contact - Success</title>
                    <meta name="description" content="Pour chaque situation, nous essayons de trouver une solution. Nous sommes à votre écoute pour vous aider dans vos projets success." />
                </Helmet>
                <Header />
                <div class="container">
                    <div class="remerciement">
                        <p>Votre requête a bien été prise en compte.<br/><br/>
                            Pour terminer l’ouverture de compte en ligne nous devons procéder à votre identification. <br/><br/>
                            Vous avez les possibilités suivantes :<br/><br/>
                            <div class="identification">
                                <div onClick={e => {
                                    window.open(this.state.url, "_self");
                                }}>
                                    <h2>Auto-identification</h2>
                                    <Lottie 
                                        options={identificationOptions}
                                        height={'auto'}
                                        width={200}
                                    />
                                    <p>Il vous suffit d’avoir une pièce d’identité (passeport ou carte d’identité), un téléphone portable, tablette ou un ordinateur muni d’une caméra. Si vous optez pour cette voie, l’auto-identification va s’effectuer par notre partenaire Swisscom.</p>
                                </div>
                                <div>
                                    <Link to={{ pathname: '/client-cree', state: { token: this.state.token } }}>
                                        <h2>Identification à la Banque</h2>
                                        <Lottie 
                                            options={agendaOptions}
                                            height={'auto'}
                                            width={200}
                                        />
                                        <p>Un de nos collaborateurs va vous contacter afin de fixer un rendez-vous. Munissez-vous d’une pièce d’identification valable (passeport, carte d’identité ou permis de conduire CH) pour l’entrevue. Nous imprimerons votre contrat et vous pourrez le signer sur place.</p>
                                    </Link>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
                <Footer />
                <Copyright />
            </Fragment>

        )
    }


}

export default ChoixSubscription
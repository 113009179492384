import React, { Component }  from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import mapStyle from './mapStyle'



class SimpleMap extends Component  {

  render(){
    const mapStyles = {
      width: "100%",
      height: "100%",
    };
    return (
      <Map
      google={this.props.google}
      zoom={15}
      style={mapStyle}
      initialCenter={{ lat: 46.495181, lng: 6.390970 }}
    >
    <Marker position={{ lat: 46.495181, lng: 6.390970 }} /></Map>
  

    );
  }
 
}

SimpleMap.defaultProps = mapStyle;

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514'
})(SimpleMap) ;

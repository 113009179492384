import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import SideBtnLink from '../Aside/SideBtnLink'
import { useQuery, gql } from '@apollo/client';

const MESSAGE = gql`
    query {
        allMessages {
            Titre
            Description
            Actif
        }
    }
`;


const HeaderNav = () => {
    
    const [active, setActive] = useState('/');
    const [message, setMessage] = useState('');

    
    const { loading, error, data } = useQuery(MESSAGE, {
        onError(error) {
            
        },
        onCompleted (complete) {
            console.log('complete : ', complete?.allMessages);
            if (complete?.allMessages?.length && complete?.allMessages[0]?.Actif) {
                setMessage(complete?.allMessages[0]?.Description)
                var modal = document.getElementById("myModal");
                if (modal) {
                    modal.style.display = "block";
                }
            }
        }
    });

    const closePopup = () => {
        var modal = document.getElementById("myModal");
        if (modal) {
            modal.style.display = "none";
        }
    }

    useEffect(() => {
        setActive(window.location.pathname);
        handleResponsiveClick()
    }, [])

    const handleResponsiveClick = () => {
        const node = document.createElement('script');
        node.src = '/js/responsive.js';
        node.type = 'text/javascript';
        node.id = 'burgerId';
        if (document.getElementById('burgerId')) {
            document.getElementById('burgerId').remove()
        } 
        
        document.getElementById('body').appendChild(node);
        if (document.getElementById('scriptCharged')) {
            const nodescript = document.createElement('script')
            nodescript.src = "/js/slideScriptResp.js"
            nodescript.id = "scriptCharged"
            document.getElementById('body').append(nodescript)
        }
      
    }

    return (
        <>
            <nav>
                <ul  >
                    <li><NavLink to="/" activeClassName={active === '/' ? 'selected' : ''}>Accueil</NavLink></li>
                    <li><NavLink to="/LaBanque" activeClassName="selected">La Banque</NavLink></li>
                    <li><NavLink to="/Comptes" activeClassName="selected">Comptes</NavLink></li>
                    <li><NavLink to="/Credits" activeClassName="selected">Crédits</NavLink></li>
                    <li><NavLink to="/Services" activeClassName="selected">Services</NavLink></li>
                    <li><NavLink to="/VosProjets" activeClassName="selected">Vos Projets</NavLink></li>
                    <li><NavLink to="/Actualites" activeClassName="selected">Actualités</NavLink></li>
                    <li><NavLink to="/AideFaq" activeClassName="selected">Aide & FAQ</NavLink></li>
                    <li><NavLink to="/Contact" activeClassName="selected">Contact</NavLink></li>
                    <li><a href="https://wwwsec.ceanet.ch/authen/login?lang=frt"   target="_blank" className="e-banking">E-banking</a></li>
                </ul>
                <SideBtnLink />
            </nav>

            <div className="burger">
            </div>

            

            <div id="myModal" className="modal">
                <div className="modal-content">
                    <span className="close" onClick={closePopup}>×</span>
                    <div dangerouslySetInnerHTML={{__html: message}}></div>
                    <button
                    onClick={closePopup}
                    className="ant-btn"
                    style={{float: 'right', background: 'rgb(27, 50, 126)', color: 'white'}}
                    >
                    <span>Fermer</span>
                    </button>
                </div>
            </div>

        </>
    )
}

export default HeaderNav

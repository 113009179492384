import React, { Fragment, useState } from 'react'
import AsideContentService from '../../Aside/AsideContentService'
import Famille from '../../../assets/images/header_services.jpg'
import { useQuery, gql } from '@apollo/client';

const ACCROCHES = gql`
    query {
        allPages (

            where: {
                Nom: "SERVICE"
            }
        ){
            id
            Nom
            Accroches (
                first: 1
                orderBy: "Priorite"
            ){
                id
                Titre
                SousTitre
                Description
                Links {
                    id
                    Nom
                    Lien
                }
            }
        }
    }
`;

const SERVICES = gql`
    query {
        allCategorieServices {
        id
        Nom
        Services {
            id
            Titre
            Description
            Contenu
        }
        }
    }
`;

function ServiceArticle() {
    const [currentCollapse, setCollapse] = useState([]);
    const { loading: loadingService, error: errorService, data: dataService } = useQuery(SERVICES);
    const { loading, error, data } = useQuery(ACCROCHES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    if (loadingService) return <p>Loading...</p>;
    if (errorService) return <p>Error :(</p>;

        const addCollapse = (id) => {
            if(currentCollapse.includes(id)) {
                setCollapse(currentCollapse.filter((e)=>(e !== id)));
            } else {
                setCollapse([...currentCollapse, id ]);
            }
        }

    return (
        <Fragment>
            <div className="slide-home">
                <div className="content-slides">
                    <div className="images">
                        <img src={Famille} alt="Ceanet" />
                    </div>
                </div>
            </div>
            {
                data && data.allPages && data.allPages[0] && data.allPages[0].Accroches ? data.allPages[0].Accroches.map((accroche) => (
                    <div key={accroche.id} className="col-md-12 info-home">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="info-contact info-one-column">
                                        <h1>{accroche.Titre}</h1>
                                        <p>{accroche.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )): ''
            }
            <div id="main-wrapper" className="col-md-12">
                <div className="container">
                    <div className="side-left">
                    {
                        dataService && dataService.allCategorieServices ? dataService.allCategorieServices.map((categorie) => (
                            <div key={categorie.id} className="collapsed-row">
                                <h2>{categorie.Nom}</h2>
                                {
                                    categorie && categorie.Services ? categorie.Services.map((service) => (
                                        <div key={service.id}>
                                            <h3 onClick={() =>
                                                addCollapse(service.id)
                                            } className={currentCollapse.includes(service.id) ? 'collapsible active' : 'collapsible'}>
                                              {}  <span className="aide_content_title">{service.Titre}</span>
                                            </h3>
                                            <div className="content-collapse-projet" style={{ display: currentCollapse.includes(service.id) ? 'block' : 'none' }}>
                                                <div className="conditions">
                                                  {/*  <p>{service.Titre}</p><br />*/}
                                                    <p> {service.Description}</p>
                                                </div>
                                                <div className="divider hide-divider"></div>
                                                <div className="conditions">

                                                    <div dangerouslySetInnerHTML={{__html: service.Contenu}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''
                                }
                            </div>
                        )) : ''
                    }
                    </div>
                    <AsideContentService />
                </div>
            </div>
        </Fragment>
    )


}

export default ServiceArticle

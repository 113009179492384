import React from 'react';
import { useQuery, gql } from '@apollo/client';
import RapportAnnuel from './RapportAnnuel';
import { useHistory, Link } from 'react-router-dom';

const lastActualities = gql`
    query {
        allArticles (
        first: 4
        sortBy: datePublication_DESC
        ) {
        id
        Titre
        Contenu
        Important
        Image {
            filename
        }
        datePublication
        Theme {
            Nom
        }
        }
    }
`;


function AsideContentActualite() {
    let history = useHistory();
    const { loading, error, data } = useQuery(lastActualities);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;



    return (
        <div className="side-right">
            <h2>Actualités</h2>
            <div className="actualites">
                {
                    data.allArticles.map((article, index) => (
                        <article key={article.id}>
                            <Link to={{pathname:`/Actualites/${article.id}`,state: { fromHome: true }}}>
                                <img src={'uploads/' + article.Image.filename} alt={article.Image.filename} />
                            </Link>
                            <div className="info">
                                <div className="date">
                                    {
                                        new Intl.DateTimeFormat("fr-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit"
                                        }).format(new Date(article.datePublication))
                                    }
                                </div>
                                <Link to={{pathname:`/Actualites/${article.id}`,state:{ fromHome: true }}}>{article.Titre}</Link>
                                <p dangerouslySetInnerHTML={{ __html: index === 0 ? (article.Contenu && article.Contenu.length > 400 ? article.Contenu.substr(0, 400) + ' ...' : article.Contenu) : '' }} ></p>
                            </div>
                        </article>
                    ))
                }

                <Link  to={{pathname:`/Actualites`,state: { fromHome: true }}} className="link-voir-plus icon-fleche-right">
                    <span>Voir plus</span>
                </Link>
            </div>
            <RapportAnnuel />
        </div>
    )
}

export default AsideContentActualite

import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import ServiceArticle from './ServiceArticle'
import SideBtnLink from '../../Aside/SideBtnLink'
import Copyright from '../../Footer/Copyright'
import Section from '../../Article/Section'
import { Helmet } from 'react-helmet'

function NosServices() {

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <Fragment>
          <Helmet>
            <title>Caisse d’épargne d’Aubonne - Services</title>
            <meta name="description" content="Nous offrons de nombreux services simples mais efficaces pour gérer votre quotidien." />
          </Helmet>
            <Header />
            <SideBtnLink />
            <ServiceArticle />
            <Section />
            <Footer />
            <Copyright />
        </Fragment>

    )
}

export default NosServices

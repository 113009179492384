import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import SideBtnLink from '../../Aside/SideBtnLink'
import Section from '../../Article/Section'
import AideFaqActualite from '../AideFaq/AideFaqActualite'
import Copyright from '../../Footer/Copyright'
import { Helmet } from 'react-helmet'


function AideFaq() {

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <Fragment>
          <Helmet>
            <title>Caisse d’épargne d’Aubonne - Aide&FAQ</title>
            <meta name="description" content="Nous avons rédigé un certain nombre de tutoriels pour vous accompagner pas à pas dans la prise en charge de nos services. Dans ce portail, vous trouverez également les conditions générales de certains de nos services." />
          </Helmet>
            <Header />
            <SideBtnLink />
            <AideFaqActualite />
            <Section />
            <Footer />
            <Copyright />
        </Fragment>

    )
}

export default AideFaq

import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import ProjetArticle from '../VosProjets/ProjetArticle'
import SideBtnLink from '../../Aside/SideBtnLink'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import Section from '../../Article/Section'
import { Helmet } from 'react-helmet'
// import Cookies from '../../Cookies/cookies'

function VosProjets() {

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <div>
            <Fragment>
              <Helmet>
                <title>Caisse d’épargne d’Aubonne - Vos Projets</title>
                <meta name="description" content="A la caisse d’Epargne d’Aubonne, nous estimons que chaque projet mérite d’être explorer dans sa singularité. Franchissons le pas ensemble." />
              </Helmet>
                <Header />
                <SideBtnLink />
                <ProjetArticle />
                <Section />
                <Footer />
                <Copyright />
            </Fragment>

        </div>
    )
}

export default VosProjets

import React from 'react'
import ReCAPTCHA from './Captcha/Captcha'

const AllInfo = ({ input, typeCompte, pays, etatCivil, setRecaptCha,rdv ,remarque,dateofbirth, OIDprocess}) => {

    var myDate = new Date(rdv.date);
    myDate = myDate.getDate() + '.' + (myDate.getMonth() + 1) + '.' + myDate.getFullYear();

    var myDateofbirth = new Date(dateofbirth.date)
    myDateofbirth = myDateofbirth.getDate() + '.' + (myDateofbirth.getMonth() + 1) + '.' + myDateofbirth.getFullYear();

    return (
        <>
            <div class="divider_2"></div>
            <h2>Ouverture de compte - Contrôle et validation</h2>
            <p style={{ color: '#848484' }}>Cette page récapitule toutes les informations fournies. Veuillez vérifier leur exactitude et lorsque vous êtes prêts, confirmez votre demande d’ouverture de compte en cliquant sur « Envoi ».</p>
            <div class="divider_2"></div>
            <div className="allInfo-form">
                <div className="allInfoFields ">
                    <div className="allInfo-couple-label-input-next">
                        <p><span className="spanName">Nom </span>{input.name}</p>
                        <p><span className="spanName">Mail  </span>{input.mail}</p>
                    </div>
                </div>
                <div className="allInfoFields ">
                    <div className="allInfo-couple-label-input-next">
                        <p><span className="spanName">Prénom</span> {input.firstname}</p>
                        <p><span className="spanName">Téléphone</span>{input.phoneNumber}</p>
                    </div>
                </div>
                <div className="allInfoFields  allInfoFieldsTypecompte">
                    <div className="allInfo-couple-label-input-next">
                        <div className="divspanName"> <span className="spanName">Adresse</span></div>
                        <div className="divdetail-adresse">
                            <span className="adresse-detail">{input.home.street}</span>
                            <span className="adresse-detail">{input.home.street_number}</span>
                            <br/>
                            <span className="adresse-detail">{input.home.zip}</span>
                            <span className="adresse-detail">{input.home.country}</span>
                        </div>
                    </div>
                    <div style={{float:"left"}} className="allInfo-couple-label-input-next">
                        {
                            (typeCompte.compteCourant || typeCompte.compteEpargne || typeCompte.comptePrevoyance || typeCompte.obligationCaisse)  && ( <div className="row">
                            <div className="col-md-6">
                                <p><span className="typedecompte">Type de compte</span> </p>
                            </div>
                            <div className="col-md-6">
                                <p> {typeCompte && typeCompte.compteCourant ? typeCompte.compteCourant: null} </p>
                                <p> {typeCompte && typeCompte.compteEpargne ? typeCompte.compteEpargne: null} </p>
                                <p> {typeCompte && typeCompte.comptePrevoyance ? typeCompte.comptePrevoyance: null} </p>
                                
                                <p> {typeCompte && typeCompte.obligationCaisse ? typeCompte.obligationCaisse: null} </p>
                            </div>
                        </div>)
                        }
                    </div>
                </div>

                <div className="allInfoFields col-4" style={{clear:"both"}}>
                    <div className="allInfo-couple-label-input-next">
                        <p><span className="spanName">Date de naissance</span>{myDateofbirth}</p>
                    </div>
                </div>
                
                {
                    OIDprocess && 
                    <div className="allInfoFields col-4">
                        <div className="allInfo-couple-label-input-next">
                            <p><span className="spanName">Nationalité</span>{pays}</p>
                        </div>
                    </div>
                }
                
                {
                    OIDprocess && 
                    <div className="allInfoFields col-4">
                        <div className="allInfo-couple-label-input-next">
                            <p><span className="spanName">Etat civil</span>{etatCivil}</p>
                        </div>
                    </div>
                }
               
                <div className="allInfoFields remarque_div ">
                    <div className="remarque-div-content">
                    <p style={{ color: "#848484 !important"}}><span className="spanName-titre">Remarque</span> {remarque} </p>
                    </div>
                </div>

                {/* <div className="allInfoFields all-rv-date">
                    <div className="allInfo-couple-label-input-next">
                       <p><span className="spanName">Rendez-vous</span>{myDate} à {rdv.heure}</p>

                    </div>
                </div> */}

            </div>
            <ReCAPTCHA setRecaptCha={setRecaptCha}/>
        </>

    )
}
export default AllInfo

import React from 'react'
import { useQuery, gql } from '@apollo/client';

const lastRapports = gql`
    query {
        allRapports (
        first: 3
        sortBy: Annee_DESC
        ){
        id
        Titre
        Annee
        Fichier {
            filename
        }
        }
    }
`;

const RapportAnnuel = () => {
    const { loading: loadingRapport, error: errorRapport, data: dataRapport } = useQuery(lastRapports);
    if (loadingRapport) return <p>Loading...</p>;
    if (errorRapport) return <p>Error :(</p>;
    return (
        <>
            <h2>Rapports annuels</h2>
            <div className="side-rapport">
                {
                    dataRapport && dataRapport.allRapports.map((rapport) => (
                        <article key={rapport.id}>
                            <div className="date">{rapport.Annee}</div>
                            <div className="info">
                                <p>{rapport.Titre}</p>
                                <a href={'uploads/' + rapport.Fichier.filename} target="_blank">Télécharger</a>
                            </div>
                        </article>
                    ))
                }
            </div>
        </>
    )
}
export default RapportAnnuel

import React, { Fragment, useEffect } from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Copyright from '../../Footer/Copyright'
import SideBtnLink from '../../Aside/SideBtnLink'
import CreditArticle from './CreditArticle'
import Section from '../../Article/Section'
import { Helmet } from 'react-helmet'



function Credits() {

    useEffect(() => {
        setTimeout(() => {
            if(!window.location.hash){
                window.scrollTo(0,0);
            }

        }, 500)
    }, [])

    return (
        <Fragment>
          <Helmet>
            <title>Caisse d’épargne d’Aubonne - Crédits</title>
            <meta name="description" content="Pour chaque situation, nous avons le financement qui s’adapte à votre besoin. En tant que banque régionale, nous mettons un point d’honneur à comprendre votre situation pour vous donner un conseil et apporter une solution personnalisée." />
          </Helmet>
            <Header />
            <CreditArticle />
            <SideBtnLink />
            <Section />
            <Footer />
           <Copyright />
        </Fragment>
    )
}

export default Credits
